import { IAutoImportKey } from "#entities/account";
import { apiFetch } from "../../fetch";

interface IResult {
  props: {
    currentPage: "account";
    title: "Your service keys";
    service_keys: IAutoImportKey[];
  };
  import_ids: { key_id: string; import_id: string }[];
}

export async function fetchAccountAutoImportKeys() {
  const path = `/account/keys`;

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
