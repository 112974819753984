import { createAccountPageLoader, PageSkeleton } from "#components/pages";
import { KemonoLink } from "#components/links";

export function AdministratorDashboardPage() {
  return (
    <PageSkeleton name="admin-dashboard" title="Admin dashboard">
      <nav>
        <ul>
          <li>
            <KemonoLink url="/account/administrator/accounts">
              Accounts
            </KemonoLink>
          </li>
        </ul>
      </nav>
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader();
