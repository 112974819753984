import clsx from "clsx";
import { THUMBNAILS_PREPEND } from "#env/env-vars";
import { createPostURL } from "#lib/urls";
import { Timestamp } from "#components/dates";
import { KemonoLink } from "#components/links";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { IPost, IPostWithFavorites } from "#entities/posts";

interface IProps {
  post: IPost;
  isFavourite?: boolean;
  isServiceIconsDisabled?: boolean;
  isFavouriteProfile?: boolean;
}

const fileExtensions = [".gif", ".jpeg", ".jpg", ".jpe", ".png", ".webp"];
const someServices = ["fansly", "candfans", "boosty", "gumroad"];

export function PostCard({
  post,
  isServiceIconsDisabled,
  isFavourite = false,
  isFavouriteProfile,
}: IProps) {
  const {
    service,
    user: profileID,
    id,
    title,
    content,
    published,
    attachments,
  } = post;
  const postLink = String(createPostURL(service, profileID, id));
  const srcNS = findNamespace(post);
  const blockClassName = clsx(
    "post-card",
    srcNS.src && "post-card--preview",
    isFavourite && "post-card--fav",
  );
  const blockClassNameHeader = clsx(
    "post-card__header",
    isFavouriteProfile && "post-card__header--fav",
  );
  const blockClassNameFooter = clsx(
    "post-card__footer",
    isFavouriteProfile && "post-card__footer--fav",
  );

  return (
    <article
      className={blockClassName}
      data-id={id}
      data-service={service}
      data-user={profileID}
    >
      <KemonoLink url={postLink}>
        <header className={blockClassNameHeader}>
          {title && title !== "DM"
            ? title
            : !content || content?.length < 50
            ? content
            : `${content.slice(0, 50)}...`}
        </header>

        {!srcNS.src ? undefined : (
          <div className="post-card__image-container">
            <img
              className="post-card__image"
              src={`${THUMBNAILS_PREPEND}/thumbnail/data${srcNS.src}`}
            />
          </div>
        )}

        <footer className={blockClassNameFooter}>
          <div>
            <div>
              {!published ? undefined : <Timestamp time={published} />}
              <div>
                {!attachments?.length ? (
                  <>No attachments</>
                ) : (
                  <>
                    {attachments.length}{" "}
                    {attachments.length === 1 ? "attachment" : "attachments"}
                  </>
                )}
              </div>
            </div>
            {isServiceIconsDisabled ? undefined : (
              <img src={`/static/small_icons/${service}.png`} />
            )}
          </div>
        </footer>
      </KemonoLink>
    </article>
  );
}

interface IFavProps {
  post: IPostWithFavorites;
  isServiceIconsDisabled?: boolean;
  isFavourite?: boolean;
  isFavouriteProfile?: boolean;
}

export function PostFavoriteCard({
  post,
  isServiceIconsDisabled,
  isFavourite,
  isFavouriteProfile,
}: IFavProps) {
  const {
    service,
    user: profileID,
    id,
    title,
    content,
    published,
    attachments,
    fav_count,
  } = post;
  const postLink = String(createPostURL(service, profileID, id));
  const srcNS = findNamespace(post);
  const blockClassName = clsx(
    "post-card",
    srcNS.src && "post-card--preview",
    isFavourite && "post-card--fav",
  );
  const blockClassNameHeader = clsx(
    "post-card__header",
    isFavouriteProfile && "post-card__header--fav",
  );
  const blockClassNameFooter = clsx(
    "post-card__footer",
    isFavouriteProfile && "post-card__footer--fav",
  );

  return (
    <article
      className={blockClassName}
      data-id={id}
      data-service={service}
      data-user={profileID}
    >
      <KemonoLink url={postLink}>
        <header className={blockClassNameHeader}>
          {title && title !== "DM"
            ? title
            : !content || content?.length < 50
            ? content
            : `${content.slice(0, 50)}...`}
        </header>

        {srcNS.src && (
          <div className="post-card__image-container">
            <img
              className="post-card__image"
              src={`${THUMBNAILS_PREPEND}/thumbnail/data${srcNS.src}`}
            />
          </div>
        )}

        <footer className={blockClassNameFooter}>
          <div>
            <div>
              {published && <Timestamp time={published} />}

              <div>
                {attachments.length === 0 ? (
                  <>No attachments</>
                ) : (
                  <>
                    {attachments.length}{" "}
                    {attachments.length === 1 ? "attachment" : "attachments"}
                  </>
                )}

                <br />
                <>
                  {Math.floor(fav_count)}{" "}
                  {fav_count > 1 ? "favorites" : "favorite"}
                </>
              </div>
            </div>
            {!isServiceIconsDisabled && (
              <img src={`/static/small_icons/${service}.png`} />
            )}
          </div>
        </footer>
      </KemonoLink>
    </article>
  );
}

function findNamespace(post: IPost) {
  const srcNS: { found: boolean; src?: string } = { found: false };
  const path = post.file?.path?.toLowerCase();
  const isValidpath = path && fileExtensions.find((ext) => path.endsWith(ext));

  if (isValidpath) {
    srcNS.src = path;
  }

  if (!isValidpath && someServices.includes(post.service)) {
    const matchedFile = post.attachments.find((file) =>
      fileExtensions.find((ext) => file.path?.endsWith(ext))
    );

    srcNS.src = matchedFile?.path;
  }

  return srcNS;
}
