import { PAYSITE_LIST } from "#env/env-vars";

export function isValidPaysite(value: unknown): value is string {
  return PAYSITE_LIST.includes(value as string);
}

export function validatePaysite(value: unknown): asserts value is string {
  if (!isValidPaysite(value)) {
    throw new Error(`"${value}" is not a valid paysite.`);
  }
}
