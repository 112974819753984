import { IAccontRole, IAccount } from "#entities/account";
import { IPagination } from "#lib/pagination";
import { apiFetch } from "../../fetch";

interface IResult {
  pagination: IPagination;
  accounts: IAccount[];
  role_list: IAccontRole[];
  currentPage: "admin";
}

export async function fetchAccounts(
  page?: number,
  name?: string,
  role?: string,
  limit?: number
) {
  const path = `/account/administrator/accounts`;
  const params = new URLSearchParams();

  if (page) {
    params.set("page", String(page));
  }

  if (name) {
    params.set("name", name);
  }

  if (role) {
    params.set("role", role);
  }

  if (limit) {
    params.set("limit", String(limit));
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
