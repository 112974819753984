import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { fetchPostData } from "#api/posts";
import { createPostURL } from "#lib/urls";
import { HTTP_STATUS } from "#lib/http";
import { validatePaysite } from "#entities/paysites";

export async function loader({ params }: LoaderFunctionArgs) {
  const inputService = params.service?.trim();

  if (!inputService) {
    throw new Error("Service is required.");
  }

  validatePaysite(inputService);

  const postID = params.post_id?.trim();

  if (!postID) {
    throw new Error("Post ID is required.");
  }

  const { service, artist_id, post_id } = await fetchPostData(
    inputService,
    postID
  );

  const url = String(createPostURL(service, artist_id, post_id));

  return redirect(url, HTTP_STATUS.SEE_OTHER);
}
