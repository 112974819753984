import clsx from "clsx";
import { createDiscordChannelPageURL, createProfileLinksURL } from "#lib/urls";
import { KemonoLink } from "#components/links";

import styles from "./discord-server.module.scss";

interface IProps {
  serverID: string;
  channels: { id: string; name: string }[];
}

export function DiscordServer({ serverID, channels }: IProps) {
  return (
    <ul className={styles.channels}>
      {channels.map(({ id, name }, index) => (
        <li key={index}>
          <KemonoLink
            className={({ isActive }) =>
              clsx(styles.channel, isActive && styles.active)
            }
            url={String(createDiscordChannelPageURL(serverID, id))}
            end
          >
            #{name}
          </KemonoLink>
        </li>
      ))}
      <li className={styles.channel}>
        <KemonoLink url={String(createProfileLinksURL("discord", serverID))}>
          Linked accounts
        </KemonoLink>
      </li>
    </ul>
  );
}
