import clsx from "clsx";
import { CSSProperties } from "react";
import { IPagination } from "#lib/pagination";
import { FormRouter, IFormProps } from "#components/forms";
import { LinkButton } from "#components/links";

interface IPaginationProps {
  /**
   * ID of related pagination controller
   */
  controllerID: string;
  pagination: IPagination;
  constructURL: (page: number) => string;
  className?: string;
}

interface IPaginationControllerProps extends IFormProps {
  id: string;
  params?: Record<string, string | undefined>;
}

export function Pagination({
  controllerID,
  pagination,
  constructURL,
  className,
}: IPaginationProps) {
  const { current_page, total_pages, current_count, offset, count } =
    pagination;
  const previousPage = current_page - 1;
  const nextPage = current_page + 1;

  return (
    <div className={clsx("paginator", className)}>
      <small className="paginator__count">
        Showing {offset + 1} - {current_count} of {count}
      </small>
      <ul className="paginator__pages">
        <li className="paginator__page">
          {current_page === 1 ? (
            <span className="paginator__link">...</span>
          ) : (
            <LinkButton
              className="paginator__link"
              url={constructURL(1)}
              isNoop={false}
            >
              1
            </LinkButton>
          )}
        </li>

        <li className="paginator__page">
          {previousPage <= 1 ? (
            <span className="paginator__link">...</span>
          ) : (
            <LinkButton
              className="paginator__link"
              url={constructURL(previousPage)}
              isNoop={false}
            >
              {previousPage}
            </LinkButton>
          )}
        </li>

        <li
          className={clsx("paginator__page", "paginator__page--current")}
          style={
            {
              ["--local-width"]: `${String(total_pages).length + 4}em`,
            } as CSSProperties
          }
        >
          <input
            className="form__input paginator__input"
            form={controllerID}
            type="number"
            name="page"
            defaultValue={current_page}
            min={1}
            max={total_pages}
            step={1}
          />
          <button
            className={clsx(
              "form__button",
              "form__button--submit",
              "paginator__submit"
            )}
            type="submit"
            form={controllerID}
          >
            Go!
          </button>
        </li>

        <li className="paginator__page">
          {nextPage >= total_pages - 1 ? (
            <span className="paginator__link">...</span>
          ) : (
            <LinkButton
              className="paginator__link"
              url={constructURL(nextPage)}
              isNoop={false}
            >
              {nextPage}
            </LinkButton>
          )}
        </li>

        <li className="paginator__page">
          {current_page === total_pages ? (
            <span className="paginator__link">...</span>
          ) : (
            <LinkButton
              className="paginator__link"
              url={constructURL(total_pages)}
              isNoop={false}
            >
              {total_pages}
            </LinkButton>
          )}
        </li>
      </ul>
    </div>
  );
}

export function PaginationController({
  id,
  params,
  className,
  ...props
}: IPaginationControllerProps) {
  return (
    <FormRouter
      id={id}
      className={clsx("paginator__controller", className)}
      {...props}
      method="GET"
      target="_self"
    >
      {!params
        ? undefined
        : Object.entries(params).map(
            ([key, value]) =>
              value && <input type="hidden" name={key} value={value} />
          )}
    </FormRouter>
  );
}
