import clsx from "clsx";
import { IImageBaseProps, ImageBase } from "./fancy_image";
import { FancyLink, IFancyLinkProps } from "#components/links";

interface IProps
  extends IFancyLinkProps,
    Omit<IImageBaseProps, "src">,
    Pick<Partial<IImageBaseProps>, "src"> {}

export function ImageLink({
  url,
  isNoop,
  src = url,
  srcset,
  isLazy,
  alt,
  className,
  children,
}: IProps) {
  return (
    <FancyLink
      className={clsx("image-link", className)}
      url={url}
      isNoop={isNoop}
    >
      {children ?? (
        <ImageBase src={src} srcset={srcset} isLazy={isLazy} alt={alt} />
      )}
    </FancyLink>
  );
}
