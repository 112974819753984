import clsx from "clsx";
import { INotification, notificationTypes } from "#entities/account";
import { Timestamp } from "#components/dates";

interface IProps {
  notification: INotification;
}

export function NotificationItem({ notification }: IProps) {
  const { type, extra_info, isSeen, created_at } = notification;

  return (
    <li
      className={clsx(
        "notifications__item",
        isSeen && "notifications__item--seen"
      )}
    >
      <span className="notifications__date">
        <Timestamp time={created_at} />
      </span>
      <span className="notifications__message">
        {notificationTypes[type](extra_info)}
      </span>
    </li>
  );
}
