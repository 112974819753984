import { IArchiveFile } from "#entities/files";
import { apiFetch } from "../fetch";

interface IResult {
  archive: IArchiveFile | null
  file_serving_enabled: boolean
}

export async function fetchArchiveFile(fileHash: string) {
  const path = `/posts/archives/${fileHash}`

  const result = await apiFetch<IResult>( path, { method: "GET" })

  return result
}

export async function fetchSetArchiveFilePassword(
  archiveHash: string,
  password: string
) {
  const path = `/set_password`;
  const params = new URLSearchParams([
    ["file_hash", archiveHash],
    ["password", password],
  ]);

  const result = await apiFetch(path, { method: "GET" }, params);

  return result;
}
