import { InvalidErrorType } from "./invalid";

export function isError(input: unknown): input is Error {
  return input instanceof Error;
}

export function ensureError(input: unknown): asserts input is Error {
  if (!isError(input)) {
    throw new InvalidErrorType(input);
  }
}
