import { ReactNode } from "react";
import { Image } from "#components/images";

interface IProps {
  children?: ReactNode;
}

export function LoadingIcon({ children }: IProps) {
  return !children ? (
    <span className="loading-icon">
      <Image src="/static/loading.gif" alt="loading progress spinner" />
    </span>
  ) : (
    <span className="loading-icon">
      <Image src="/static/loading.gif" alt="loading progress spinner" />{" "}
      {children}
    </span>
  );
}
