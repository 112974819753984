import clsx from "clsx";
import { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { SITE_NAME } from "#env/env-vars";

export interface IPageProps
  extends Pick<ComponentPropsWithoutRef<"section">, "onClick"> {
  name: string | string[];
  title: string;
  heading?: string | ReactElement;
  className?: string;
  children: ReactNode;
}

export function PageSkeleton({
  name,
  className,
  title,
  heading,
  children,
  onClick,
}: IPageProps) {
  return (
    <section
      className={clsx(
        "site-section",
        !Array.isArray(name)
          ? `site-section--${name}`
          : name.map((sectionName) => `site-section--${sectionName}`),
        className
      )}
      onClick={onClick}
    >
      <Helmet>
        <title key="title">{`${title} | ${SITE_NAME}`}</title>
      </Helmet>

      {!heading ? undefined : (
        <header className="site-section__header">
          <h1 className="site-section__heading">{heading}</h1>
        </header>
      )}

      {children}
    </section>
  );
}
