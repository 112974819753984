import { useLoaderData } from "react-router-dom";
import {
  createAccountDMsReviewPageURL,
  createAccountFavoritePostsPageURL,
  createAccountFavoriteProfilesPageURL,
  createAccountImportKeysPageURL,
  createAccountNotificationsPageURL,
  createAccountPageURL,
  createAccountPasswordChangePageURL,
  createAdministratorPageURL,
  createModeratorPageURL,
} from "#lib/urls";
import { fetchAccount } from "#api/account";
import { IAccount } from "#entities/account";
import { PageSkeleton, createAccountPageLoader } from "#components/pages";
import { KemonoLink } from "#components/links";

interface IProps {
  account: IAccount;
  notificationsCount: number;
}

export function AccountPage() {
  const { account, notificationsCount } = useLoaderData() as IProps;
  const { role, username, created_at } = account;
  const title = "Your account details";
  const heading = "Your Account Details";
  const roleURL = String(
    role === "administrator"
      ? createAdministratorPageURL()
      : role == "moderator"
      ? createModeratorPageURL()
      : createAccountPageURL()
  );

  return (
    <PageSkeleton name="account" title={title} heading={heading}>
      <div className="account-view">
        <div className="account-view__header">
          <span className="account-view__greeting">
            Hello,
            <span className="account-view__identity">{username}</span>
          </span>

          <div className="account-view__info">
            Joined {created_at} |
            <span className="account-view__role">
              <KemonoLink url={roleURL} isNoop={false}>
                {role}
              </KemonoLink>
            </span>
          </div>
        </div>

        <p className="def-list__section">
          Favorites:
          <ul>
            <li>
              <KemonoLink
                url={String(createAccountFavoriteProfilesPageURL())}
                isNoop={false}
              >
                Profiles
              </KemonoLink>
            </li>
            <li>
              <KemonoLink
                url={String(createAccountFavoritePostsPageURL())}
                isNoop={false}
              >
                Posts
              </KemonoLink>
            </li>
          </ul>
        </p>

        <p className="def-list__section">
          Notifications:
          <span>
            <KemonoLink
              url={String(createAccountNotificationsPageURL())}
              isNoop={false}
            >
              {notificationsCount}
            </KemonoLink>
          </span>
        </p>

        <p className="def-list__section">
          <KemonoLink
            url={String(createAccountImportKeysPageURL())}
            isNoop={false}
          >
            Keys
          </KemonoLink>
        </p>

        <p className="def-list__section">
          <KemonoLink
            url={String(createAccountDMsReviewPageURL())}
            isNoop={false}
          >
            Review DMs
          </KemonoLink>
        </p>

        <p className="def-list__section">
          <KemonoLink
            url={String(createAccountPasswordChangePageURL())}
            isNoop={false}
          >
            Change password
          </KemonoLink>
        </p>
      </div>
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader(
  async function loader(): Promise<IProps> {
    const { props } = await fetchAccount();
    const { account, currentPage, notifications_count, title } = props;

    return {
      account,
      notificationsCount: notifications_count,
    };
  }
);
