import { apiFetch } from "../../fetch";

interface IBody {
  approved_hashes: string[];
  delete_ignored?: boolean;
}

export async function fetchApproveDMs(
  hashes: string[],
  isIgnoredDeleted?: boolean
) {
  const path = `/account/review_dms`;
  const body: IBody = {
    approved_hashes: hashes,
    delete_ignored: isIgnoredDeleted,
  };

  const result = await apiFetch<true>(path, { method: "POST", body });

  return result;
}
