import { paysites } from "#entities/paysites";

/**
 * Apply some fixes to the content of the post.
 */
export function cleanupBody(postBody: HTMLElement, service: string) {
  const postContent = postBody.querySelector<HTMLPreElement>(
    ".post__content > pre"
  );
  const isNoPostContent =
    !postContent ||
    (!postContent.childElementCount && !postContent.childNodes.length);

  // content is empty
  if (isNoPostContent) {
    return;
  }

  // pixiv post
  if (service === "fanbox") {
    // its contents is a text node
    if (!postContent.childElementCount && postContent.childNodes.length === 1) {
      // wrap the text node into `<pre>`
      const [textNode] = Array.from(postContent.childNodes);
      const pre = document.createElement("pre");
      textNode.after(pre);
      pre.appendChild(textNode);
    }

    // remove paragraphs with only `<br>` in them
    const paragraphs = postContent.querySelectorAll("p");
    paragraphs.forEach((para) => {
      if (
        para.childElementCount === 1 &&
        para.firstElementChild?.tagName === "BR"
      ) {
        para.remove();
      }
    });
  }

  if (service === "onlyfans") {
    // replace links to profiles with internal links
    const links = postContent.querySelectorAll("a");

    Array.from(links).forEach((anchour) => {
      const literalHref = anchour.getAttribute("href")!;

      // it's not an internal href
      if (!literalHref.startsWith("/")) {
        return;
      }

      const url = new URL(literalHref, anchour.href);
      const pathname = url.pathname;
      const pathnameSegments = url.pathname
        .split("/")
        .slice(1, pathname.endsWith("/") ? -1 : undefined);

      // url is not a profile
      if (pathnameSegments.length !== 0) {
        return;
      }

      const profileID = pathnameSegments[0];
      const fixedURL = paysites["onlyfans"].user.profile(profileID);
      anchour.href = fixedURL;
    });
  }

  Array.from(document.links).forEach((anchour) => {
    // remove links to fanbox from the post
    const hostname = anchour.hostname;
    if (hostname.includes("downloads.fanbox.cc")) {
      if (anchour.classList.contains("image-link")) {
        anchour.remove();
      } else {
        let el = document.createElement("span");
        el.textContent = anchour.textContent;
        anchour.replaceWith(el);
      }
    } else if (hostname.includes("fanbox.cc")) {
      anchour.href = anchour.href.replace(
        /https?:\/\/(?:[a-zA-Z0-9-]*.)?fanbox\.cc\/(?:(?:manage\/)|(?:@[a-zA-Z\d]+\/)|)posts\/(\d+)/g,
        "/fanbox/post/$1"
      );
    } else if (hostname.includes("patreon.com")) {
      anchour.href = anchour.href.replace(
        /https?:\/\/(?:[\w-]*.)?patreon\.com\/posts\/.*\b(\d+)\b(?:\?.*)?/g,
        "/patreon/post/$1"
      );
    }
  });

  // Remove needless spaces and empty paragraphs.
  /**
   * @type {NodeListOf<HTMLParagraphElement}
   */
  const paragraphs = postContent.querySelectorAll("p:empty");
  Array.from(paragraphs).forEach((paragraph) => {
    if (
      paragraph.nextElementSibling &&
      paragraph.nextElementSibling.tagName === "BR"
    ) {
      paragraph.nextElementSibling.remove();
      paragraph.remove();
    } else {
      paragraph.remove();
    }
  });
}
