import { IUnapprovedDM } from "#entities/dms";
import { apiFetch } from "../../fetch";

interface IResult {
  currentPage: "import";
  account_id: number;
  status: "ignored" | "pending";
  dms: IUnapprovedDM[];
}

export async function fetchDMsForReview(status?: "ignored" | "pending") {
  const path = `/account/review_dms`;
  const params = new URLSearchParams();

  if (status) {
    params.set("status", status);
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
