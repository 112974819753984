import { PageSkeleton } from "#components/pages";

export function FanboxImportsPage() {
  const title = "Fanbox Importer";
  const heading = "Fanbox Importer";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <p>
        Auto-Import is disabled, please import the keys at your own discretion.
      </p>
      <p>
        While we made sure to take steps to prevent detection and association of
        the accounts with our importing, we can not make any guarantees.
      </p>
      <p>
        This is a test run that is not optimized for performance but rather for
        consistency and human emulation.
      </p>
      <p>
        The system is accessible in this testing phase, if you submit your
        fanbox key it should start importing most of your data with the improved
        importer logic.
      </p>
      <p>
        Once the testing is finished, we will scale up the services required for
        more performant importing.
      </p>
      <p>
        In case of you getting a notification from fanbox, do contact us via
        Telegram and Chan that can be found in the bottom of the menu to the
        left, or send an email to{" "}
        <a href="mailto:contact@kemono.su">contact@kemono.su</a>
      </p>
      <p>Do include the content/screenshot of the notification from fanbox.</p>
    </PageSkeleton>
  );
}
