import { IShare } from "#entities/files";
import { InternalURL } from "./internal-url";

export function createSharesPageURL(
  offset?: number
) {
  const path = `/shares`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  return new InternalURL(path, params);
}

export function createSharePageURL(shareID: IShare["id"]) {
  const path = `/share/${shareID}`;

  return new InternalURL(path);
}
