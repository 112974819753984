import { IAccount } from "#entities/account";
import { InternalURL } from "./internal-url";

export function createAccountsPageURL(
  page?: number,
  name?: string,
  role?: string,
  limit?: number
) {
  const path = `/account/administrator/accounts`;
  const params = new URLSearchParams();

  if (page) {
    params.set("page", String(page));
  }

  if (name) {
    params.set("name", name);
  }

  if (role) {
    params.set("role", role);
  }

  if (limit) {
    params.set("limit", String(limit));
  }

  return new InternalURL(path);
}

export function createAccountDetailsPageURL(id: IAccount["id"]) {
  const path = `/account/administrator/accounts/${id}`;

  return new InternalURL(path);
}
