import { IArtist } from "#entities/profiles";
import { ITag } from "#entities/tags";
import { apiFetch } from "../fetch";

interface IResult {
  props: {
    display_data: {
      service: string;
      href: string;
    };
    artist: IArtist;
    service: string;
    id: string;
    share_count: number;
    dm_count: number;
    has_links: "✔️" | "0";
  };

  tags: ITag[];
  service: string;
  artist: IArtist;
}

export async function fetchProfileTags(service: string, profileID: string) {
  const path = `/${service}/user/${profileID}/tags`;
  const tags = await apiFetch<IResult>(path, { method: "GET" });

  return tags;
}
