import { ARTISTS_OR_CREATORS, SITE_NAME } from "#env/env-vars";
import { PageSkeleton } from "#components/pages";

export function ImporterTutorialFanboxPage() {
  return (
    <PageSkeleton name="" title="How to get your Fanbox session key">
      <p>
        {SITE_NAME} needs your session key in order to access posts from the{" "}
        {ARTISTS_OR_CREATORS.toLowerCase()} you are subscribed to.
      </p>

      <p>
        After going to the paysite you want to import and signing in, (
        <a href="https://www.fanbox.cc/">Fanbox</a>) you need to find where
        cookies are located in your browser, follow the instructions bellow.
      </p>

      <h2 id="chrome">Chrome</h2>
      <ul>
        <li>
          Press F12 to open Developer tools. If it didn't work for you try
          Ctrl+Shift+I or right click inspect element.
        </li>
        <li>
          In the menu at the top, navigate to "Application" tab, if this isn't
          visible at a first glance simply press {">"}
          {">"} for more tabs.
        </li>
        <img
          src="/static/devtools_pick_application.png"
          alt="Select Application in Developer tools."
        />
        <li>In the "Application" tab, go to "Cookies".</li>
        <li>Within the "Cookies" dropdown, select "fanbox.cc".</li>
        <li>
          Now in list of cookies find FANBOXSESSID and select it, copy the
          contents and that will be the value you will use.
        </li>
        <img
          src="/static/copy_cookie_fanbox.png"
          alt="Copy cookie in the correct menu"
        />
        <li>
          Paste the content of the cookie you copied and submit in the{" "}
          {SITE_NAME} import page
        </li>
      </ul>

      <h2 id="safari">Safari</h2>
      <ul>
        <li>
          Ensure &quot;Show Develop Menu&quot; is enabled in Preferences (
          <code>⌘,</code>)
        </li>
        <li>
          Open Web Inspector (<code>⌘⌥I</code>)
        </li>
        <li>Go to Storage &gt; Cookies</li>
        <li>
          Right-click the cookie for your service and click &quot;Copy&quot;
        </li>
      </ul>

      <h2 id="firefox">Firefox</h2>
      <ul>
        <li>Open DevTools by pressing F12 and open the Storage tab</li>
        <li>Go to Cookies &gt; [site]</li>
        <li>Go to Storage &gt; Cookies</li>
        <li>
          Right-click the cookie for your service and click &quot;Copy&quot;
        </li>
      </ul>
      <p>
        For other browsers, please consult browser documentation on how to
        access stored cookies.
      </p>
    </PageSkeleton>
  );
}
