/*
  https://webpack.js.org/plugins/define-plugin/
*/

import type { INavItem } from "#components/layout";

export const KEMONO_SITE = BUNDLER_ENV_KEMONO_SITE;

export const SENTRY_DSN = BUNDLER_ENV_SENTRY_DSN;

export const SITE_NAME = BUNDLER_ENV_SITE_NAME;

export const NODE_ENV = process.env.NODE_ENV;

export const ICONS_PREPEND = BUNDLER_ENV_ICONS_PREPEND;

export const BANNERS_PREPEND = BUNDLER_ENV_BANNERS_PREPEND;

export const THUMBNAILS_PREPEND = BUNDLER_ENV_THUMBNAILS_PREPEND;

export const CREATORS_LOCATION = BUNDLER_ENV_CREATORS_LOCATION;

export const ARTISTS_OR_CREATORS = BUNDLER_ENV_ARTISTS_OR_CREATORS;

export const DISABLE_DMS = BUNDLER_ENV_DISABLE_DMS;

export const DISABLE_FAQ = BUNDLER_ENV_DISABLE_FAQ;

export const DISABLE_FILEHAUS = BUNDLER_ENV_DISABLE_FILEHAUS;

export const SIDEBAR_ITEMS = BUNDLER_ENV_SIDEBAR_ITEMS;

export const FOOTER_ITEMS = BUNDLER_ENV_FOOTER_ITEMS;

/**
 * b64-encoded string
 */
export const BANNER_GLOBAL = BUNDLER_ENV_BANNER_GLOBAL;

/**
 * b64-encoded string
 */
export const BANNER_WELCOME = BUNDLER_ENV_BANNER_WELCOME;

export const HOME_BACKGROUND_IMAGE = BUNDLER_ENV_HOME_BACKGROUND_IMAGE;

export const HOME_MASCOT_PATH = BUNDLER_ENV_HOME_MASCOT_PATH;

export const HOME_LOGO_PATH = BUNDLER_ENV_HOME_LOGO_PATH;

/**
 * b64-encoded string
 */
export const HOME_WELCOME_CREDITS = BUNDLER_ENV_HOME_WELCOME_CREDITS;

export const PAYSITE_LIST = BUNDLER_ENV_PAYSITE_LIST;

/**
 * b64-encoded string
 */
export const HEADER_AD = BUNDLER_ENV_HEADER_AD;

/**
 * b64-encoded string
 */
export const MIDDLE_AD = BUNDLER_ENV_MIDDLE_AD;

/**
 * b64-encoded string
 */
export const FOOTER_AD = BUNDLER_ENV_FOOTER_AD;

/**
 * b64-encoded string
 */
export const SLIDER_AD = BUNDLER_ENV_SLIDER_AD;

/**
 * b64-encoded JSON string
 */
export const VIDEO_AD = BUNDLER_ENV_VIDEO_AD;

export const IS_ARCHIVER_ENABLED = BUNDLER_ENV_IS_ARCHIVER_ENABLED;

export const API_SERVER_BASE_URL = BUNDLER_ENV_API_SERVER_BASE_URL;

export const API_SERVER_PORT = BUNDLER_ENV_API_SERVER_PORT;

export const GIT_COMMIT_HASH = BUNDLER_ENV_GIT_COMMIT_HASH;

// stolen from https://stackoverflow.com/a/76844373
declare global {
  const BUNDLER_ENV_KEMONO_SITE: string;
  const BUNDLER_ENV_SENTRY_DSN: string | undefined;
  const BUNDLER_ENV_SITE_NAME: string;
  const BUNDLER_ENV_ICONS_PREPEND: string;
  const BUNDLER_ENV_BANNERS_PREPEND: string;
  const BUNDLER_ENV_THUMBNAILS_PREPEND: string;
  const BUNDLER_ENV_CREATORS_LOCATION: string;
  const BUNDLER_ENV_ARTISTS_OR_CREATORS: string;
  const BUNDLER_ENV_DISABLE_DMS: boolean;
  const BUNDLER_ENV_DISABLE_FAQ: boolean;
  const BUNDLER_ENV_DISABLE_FILEHAUS: boolean;
  const BUNDLER_ENV_SIDEBAR_ITEMS: INavItem[];
  const BUNDLER_ENV_FOOTER_ITEMS: unknown[] | undefined;
  const BUNDLER_ENV_BANNER_GLOBAL: string | undefined;
  const BUNDLER_ENV_BANNER_WELCOME: string | undefined;
  const BUNDLER_ENV_HOME_BACKGROUND_IMAGE: string | undefined;
  const BUNDLER_ENV_HOME_MASCOT_PATH: string | undefined;
  const BUNDLER_ENV_HOME_LOGO_PATH: string | undefined;
  const BUNDLER_ENV_HOME_WELCOME_CREDITS: string;
  const BUNDLER_ENV_PAYSITE_LIST: string[];
  const BUNDLER_ENV_HOME_ANNOUNCEMENTS: IAnnouncement[] | undefined;
  const BUNDLER_ENV_HEADER_AD: string;
  const BUNDLER_ENV_MIDDLE_AD: string;
  const BUNDLER_ENV_FOOTER_AD: string;
  const BUNDLER_ENV_SLIDER_AD: string;
  const BUNDLER_ENV_VIDEO_AD: string;
  const BUNDLER_ENV_IS_ARCHIVER_ENABLED: boolean;
  const BUNDLER_ENV_API_SERVER_BASE_URL: string | undefined;
  const BUNDLER_ENV_API_SERVER_PORT: number | undefined;
  const BUNDLER_ENV_GIT_COMMIT_HASH: string | undefined;
}

interface IAnnouncement {
  title: string;
  date: string;
  content: string;
}
