import { IPopularPostsPeriod } from "#entities/posts";
import { InternalURL } from "./internal-url";

export function createPostsPageURL(
  offset?: number,
  query?: string,
  tags?: string[]
) {
  const path = `posts`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (query) {
    params.set("q", query);
  }

  if (tags) {
    for (const tag of tags) {
      params.set("tag", tag);
    }
  }

  return new InternalURL(path, params);
}

export function createPostURL(
  service: string,
  profileID: string,
  postID: string
) {
  const path = `/${service}/user/${profileID}/post/${postID}`;

  return new InternalURL(path);
}

export function createPostRevisionPageURL(
  service: string,
  profileID: string,
  postID: string,
  revisionID: string
) {
  const path = `/${service}/user/${profileID}/post/${postID}/revision/${revisionID}`;

  return new InternalURL(path);
}

export function createFileUploadPageURL(service: string, profileID: string) {
  const path = "/posts/upload";
  const searchParams = new URLSearchParams([
    ["service", service],
    ["user", profileID],
  ]);

  return new InternalURL(path, searchParams);
}

export function createAttachmentURL(
  path: string,
  name: string,
  server?: string
) {
  const pathname = `/data${path}`;
  const params = new URLSearchParams([["f", name]]);

  if (server) {
    const url = new URL(pathname, server);
    url.search = String(params);

    return url;
  }

  return new InternalURL(pathname, params);
}

export function createPreviewURL(path: string, name: string, server?: string) {
  const pathname = `/data${path}`;
  const params = new URLSearchParams([["f", name]]);

  if (server) {
    const url = new URL(pathname, server);
    url.search = String(params);

    return url;
  }

  return new InternalURL(pathname, params);
}

export function createPopularPostsPageURL(
  date?: string,
  scale?: IPopularPostsPeriod,
  offset?: number
) {
  const path = `/posts/popular`;
  const params = new URLSearchParams();

  if (date) {
    params.set("date", date);
  }

  if (scale) {
    params.set("period", scale);
  }

  if (offset) {
    params.set("o", String(offset));
  }

  return new InternalURL(path, params);
}
