import { PageSkeleton } from "#components/pages";

export function ContactPage() {
  const title = "Contact Us";
  const heading = "Contact Us";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <p>
        Questions: <a href="mailto:contact@coomer.su">contact@coomer.su</a>
      </p>
      <p>
        Legal inquiries: <a href="mailto:legal@coomer.su">legal@coomer.su</a>
      </p>
      <p>
        Advertising inquiries: <a href="mailto:ads@coomer.su">ads@coomer.su</a>
      </p>
    </PageSkeleton>
  );
}
