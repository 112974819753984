import clsx from "clsx";
import { IArtist, IArtistWithFavs } from "#entities/profiles";
import { paysites } from "#entities/paysites";
import {
  createBannerURL,
  createIconURL,
  createProfilePageURL,
} from "#lib/urls";
import { useClient } from "#hooks";
import { Image } from "#components/images";
import { Timestamp } from "#components/dates";
import { KemonoLink } from "#components/links";

interface IProps {
  artist: IArtist | IArtistWithFavs;
  isUpdated?: boolean;
  isIndexed?: boolean;
  isCount?: boolean;
  isFavorite?: boolean;
  singleOf?: string;
  pluralOf?: string;
  isDate?: boolean;
  className?: string;
}

interface IHeaderProps {
  isCount?: boolean;
  isDate?: boolean;
}

export function ArtistCard({
  artist,
  isUpdated = false,
  isIndexed = false,
  isCount = false,
  isFavorite = false,
  singleOf,
  pluralOf,
  isDate = false,
  className,
}: IProps) {
  const isClient = useClient();
  const profileLink = String(
    createProfilePageURL({
      service: artist.service,
      profileID: artist.id,
    })
  );
  const profileIcon = createIconURL(artist.service, artist.id);
  const profileBanner = createBannerURL(artist.service, artist.id);
  const updatedDateTime = new Date(Number(artist.updated) * 1000).toISOString();
  const indexedDateTime = new Date(Number(artist.indexed) * 1000).toISOString();

  return (
    <KemonoLink
      className={clsx("user-card", isFavorite && "user-card--fav", className)}
      url={profileLink}
      data-id={artist.id}
      data-service={artist.service}
      style={
        !isClient
          ? undefined
          : {
              backgroundImage: `linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 80%)), url(${profileBanner})`,
            }
      }
    >
      {/* Icon. */}
      <div className="user-card__icon">
        <div className="user-card__user-icon">
          <Image src={profileIcon} />
        </div>
      </div>

      {/* Secondary identifiers and elements. */}
      <div className="user-card__info">
        <span
          className="user-card__service"
          style={{ backgroundColor: paysites[artist.service].color }}
        >
          {paysites[artist.service].title}
        </span>

        <div className="user-card__name">{artist.name}</div>

        {isUpdated && (
          <div className="user-card__updated">
            <Timestamp time={updatedDateTime} />
          </div>
        )}

        {isIndexed && (
          <div className="user-card__updated">
            <Timestamp time={indexedDateTime} />
          </div>
        )}

        {!isCount ? undefined : (
          <div className="user-card__count">
            {"favorited" in artist ? (
              <>
                <b>{artist.favorited}</b>{" "}
                {artist.favorited > 1 ? pluralOf : singleOf}
              </>
            ) : (
              <>No {pluralOf ? pluralOf : "None"}</>
            )}
          </div>
        )}
      </div>
    </KemonoLink>
  );
}

export function ArtistCardHeader({
  isCount = false,
  isDate = false,
}: IHeaderProps) {
  return (
    <header className="user-card user-card--header">
      <div className="user-card__icon">Icon</div>
      <div className="user-card__name">Name</div>
      <div className="user-card__service">Service</div>

      {!isCount ? undefined : (
        <div className="user-card__count">Times favorited</div>
      )}

      {!isDate ? undefined : <div className="user-card__updated">Updated</div>}
    </header>
  );
}
