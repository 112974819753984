import {
  ARTISTS_OR_CREATORS,
  BANNER_WELCOME,
  HOME_BACKGROUND_IMAGE,
  HOME_LOGO_PATH,
  HOME_MASCOT_PATH,
  HOME_WELCOME_CREDITS,
  SITE_NAME,
} from "#env/env-vars";
import { AVAILABLE_PAYSITE_LIST } from "#env/derived-vars";
import { PageSkeleton } from "#components/pages";
import { KemonoLink } from "#components/links";

export function HomePage() {
  const title = "Welcome";

  return (
    <PageSkeleton name="home" title={title}>
      {!BANNER_WELCOME ? undefined : (
        <div dangerouslySetInnerHTML={{ __html: atob(BANNER_WELCOME) }} />
      )}

      <div className="jumbo-welcome">
        <div
          className="jumbo-welcome-background"
          style={
            !HOME_BACKGROUND_IMAGE
              ? undefined
              : {
                  backgroundImage: `url('${HOME_BACKGROUND_IMAGE}')`,
                }
          }
        ></div>

        {!HOME_MASCOT_PATH ? undefined : (
          <div className="jumbo-welcome-mascot">
            <div>
              <img src={HOME_MASCOT_PATH} />
            </div>
          </div>
        )}

        <Description />
      </div>

      {BUNDLER_ENV_HOME_ANNOUNCEMENTS?.map((announcement) => (
        <div className="jumbo-announcement">
          <div className="announcement-title-container">
            <h3>{announcement.title}</h3>
            <div className="date">{announcement.date}</div>
          </div>
          <p
            className="announcement-text"
            dangerouslySetInnerHTML={{ __html: announcement.content }}
          />
        </div>
      ))}
    </PageSkeleton>
  );
}

function Description() {
  return (
    <div className="jumbo-welcome-description">
      {!HOME_LOGO_PATH ? undefined : (
        <div className="jumbo-welcome-description-header">
          <img src={HOME_LOGO_PATH} width="50px" height="50px" />
        </div>
      )}

      <p>
        <strong>{SITE_NAME}</strong> is a public archiver for:
      </p>

      <ul>
        {AVAILABLE_PAYSITE_LIST.map((paysite, index) => (
          <li key={index}>{paysite.title}</li>
        ))}
      </ul>

      <p>
        Contributors here upload content and share it here for easy searching
        and organization. To get started viewing content, either search for
        creators on the{" "}
        <KemonoLink url="/artists">
          {ARTISTS_OR_CREATORS.toLowerCase()} page
        </KemonoLink>
        , or search for content on the{" "}
        <KemonoLink url="/posts">posts page</KemonoLink>. If you want to
        contribute content, head over to the{" "}
        <KemonoLink url="/importer">import page</KemonoLink>.
      </p>

      {!HOME_WELCOME_CREDITS ? undefined : (
        <div
          className="jumbo-welcome-credits"
          dangerouslySetInnerHTML={{ __html: atob(HOME_WELCOME_CREDITS) }}
        ></div>
      )}
    </div>
  );
}
