import { PageSkeleton } from "#components/pages";

export function GumroadAndCoPage() {
  const title = "Gumroad and Co";
  const heading = "Gumroad and Co";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <h4>TL;DR (browse ») == archive password sharing and validation</h4>

      <p>
        To make a long story short, you are now able to list (not view) the
        contents of the archives and submit passwords for archives that are
        password protected.
        <br />
        Upon submitting the correct password, the page will re-load and display
        the valid password.
      </p>

      <p>
        <a href="https://help.gumroad.com/article/156-gumroad-and-adult-content">
          As for gumroad, they sure are deep-throating the payment processors,
          shaft and balls.
        </a>{" "}
        We'll see how it goes, but we do know where it'll end.
        <br />
        Either way, we didn't expect this to happen this fast. The importer is
        being worked on every day to scrape whatever is missing.
        <br />
        And to you, who are submitting keys, please check if your key contains
        "...", browsers are kind of shit and will abbreviate anything that is
        "too long".
      </p>
      <p>
        If you see something missing from your imports (contents, posts, reward
        text, etc.), do contact us via Telegram and Chan, which can be found at
        the bottom of the menu to the left, or send an email to{" "}
        <a href="mailto:contact@kemono.su">contact@kemono.su</a>
      </p>

      <p>
        In regard to Account Linking. You do not need to cross-link (A{">"}B,B
        {">"}A) the profiles, a single direction is more than enough: (A{">"}B,A
        {">"}C,A{">"}D) or (A{">"}B,B{">"}C,C{">"}D).
      </p>

      <p>
        Also, I think it was never mentioned, but there exists a cookie
        "thumbSize" for this site, which controls the size of the displayed
        tiles.
      </p>
    </PageSkeleton>
  );
}
