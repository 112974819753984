import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

interface IProps extends Omit<ComponentPropsWithoutRef<"button">, "type"> {}

export function ButtonSubmit({ className, ...props }: IProps) {
  return (
    <button
      className={clsx(
        "button",
        "form__button",
        "form__button--submit",
        className
      )}
      type="submit"
      {...props}
    />
  );
}
