import { ActionFunctionArgs, redirect } from "react-router-dom";
import { createAccountPageURL } from "#lib/urls";
import { PageSkeleton, createAccountPageLoader } from "#components/pages";
import { FormRouter } from "#components/forms";
import { fetchAccountChangePassword } from "#api/account";

export function AccountChangePasswordPage() {
  const title = "Change password";
  const heading = "Change Password";

  return (
    <PageSkeleton
      className="login-container"
      name="change-password"
      title={title}
      heading={heading}
    >
      <FormRouter id="change_password_form" className="form" method="POST">
        <div className="form__section">
          <label className="form__label" htmlFor="current-password">
            Current password:
          </label>
          <input
            id="current-password"
            className="form__input form__input--password"
            type="password"
            name="current-password"
            autoComplete="current-password"
          />
        </div>

        <div className="form__section">
          <label className="form__label" htmlFor="new-password">
            New password:
          </label>
          <input
            id="new-password"
            className="form__input form__input--password"
            type="password"
            name="new-password"
            autoComplete="new-password"
          />
        </div>

        <div className="form__section">
          <label className="form__label" htmlFor="new-password-confirmation">
            Confirm new password:
          </label>
          <input
            id="new-password-confirmation"
            className="form__input form__input--password"
            type="password"
            name="new-password-confirmation"
            autoComplete="new-password"
          />
        </div>

        <div className="form__section">
          <button
            id="submit"
            className="form__button form__button--submit"
            type="submit"
          >
            Change password
          </button>
        </div>
      </FormRouter>
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader();

export async function action({ request }: ActionFunctionArgs) {
  try {
    if (request.method !== "POST") {
      throw new Error(`Unknown method "${request.method}".`);
    }

    const data = await request.formData();

    const currentPassword = data.get("current-password") as string | null;

    if (!currentPassword) {
      throw new Error("Password cannot be empty.");
    }

    const newPassword = (data.get("new-password") as string | null)?.trim();

    if (!newPassword) {
      throw new Error("New password cannot be empty.");
    }

    if (newPassword.length < 5) {
      throw new Error("New password must have at least 5 characters.");
    }

    const newPasswordConfirmation = (
      data.get("new-password-confirmation") as string | null
    )?.trim();

    if (newPassword !== newPasswordConfirmation) {
      throw new Error("New password and confirmation do not match.");
    }

    await fetchAccountChangePassword(
      currentPassword,
      newPassword,
      newPasswordConfirmation
    );

    return redirect(String(createAccountPageURL()));
  } catch (error) {
    return error;
  }
}
