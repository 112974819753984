import { InternalURL } from "./internal-url";

export function createAccountPageURL() {
  const path = "/account";

  return new InternalURL(path);
}

export function createModeratorPageURL() {
  const path = `/account/moderator`;

  return new InternalURL(path);
}

export function createAdministratorPageURL() {
  const path = `/account/administrator`;

  return new InternalURL(path);
}

export function createAccountNotificationsPageURL() {
  const path = `/account/notifications`;

  return new InternalURL(path);
}

export function createAccountImportKeysPageURL() {
  const path = `/account/keys`;

  return new InternalURL(path);
}

export function createAccountDMsReviewPageURL(status?: string) {
  const path = `/account/review_dms`;
  const params = new URLSearchParams();

  if (status) {
    params.set("status", status);
  }

  return new InternalURL(path, params);
}

export function createAccountPasswordChangePageURL() {
  const path = `/account/change_password`;

  return new InternalURL(path);
}

export function createAccountFavoriteProfilesPageURL(
  offset?: number,
  sortBy?: string,
  order?: string
) {
  const path = "/account/favorites/artists";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (sortBy) {
    params.set("sort_by", sortBy);
  }

  if (order) {
    params.set("order", order);
  }

  return new InternalURL(path, params);
}

export function createAccountFavoritePostsPageURL(
  offset?: number,
  sortBy?: string,
  order?: string
) {
  const path = "/account/favorites/posts";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (sortBy) {
    params.set("sort_by", sortBy);
  }

  if (order) {
    params.set("order", order);
  }

  return new InternalURL(path, params);
}
