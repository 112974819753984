import { InvalidErrorType } from "#lib/errors";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { Preformatted } from "#components/formatting";

interface IProps {
  routePathPattern: string;
  error: InvalidErrorType;
}

export function InvalidErrorView({ routePathPattern, error }: IProps) {
  const { name, message, payload } = error;
  return (
    <DescriptionList>
      <DescriptionSection
        dKey="Route"
        dValue={<Preformatted>{routePathPattern}</Preformatted>}
      />

      <DescriptionSection
        dKey="Name"
        dValue={<Preformatted>{name}</Preformatted>}
        isHorizontal
      />

      <DescriptionSection
        dKey="Message"
        dValue={<Preformatted>{message}</Preformatted>}
      />

      <DescriptionSection
        dKey="Payload"
        dValue={<Preformatted>{String(payload)}</Preformatted>}
      />
    </DescriptionList>
  );
}
