import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { PageSkeleton } from "#components/pages";
import { ITag } from "#entities/tags";
import { createTagPageURL } from "#lib/urls";
import { fetchTags } from "#api/tags";

interface IProps {
  tags: ITag[];
}

export function TagsPage() {
  const { tags } = useLoaderData() as IProps;
  const title = "All tags";
  const heading = "All Tags";

  return (
    <PageSkeleton name="tags" title={title} heading={heading}>
      <section id="tag-container">
        {tags.map((tag) => (
          <article>
            <a href={String(createTagPageURL(tag.tag))}>
              <span>{tag.tag}</span>
              <span>{tag.post_count}</span>
            </a>
          </article>
        ))}
      </section>
    </PageSkeleton>
  );
}

export async function loader({}: LoaderFunctionArgs): Promise<IProps> {
  const { tags } = await fetchTags();

  return { tags };
}
