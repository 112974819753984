import { IShare } from "#entities/files";
import { apiFetch } from "../fetch";

interface IResult {
  base: Record<string, unknown>;
  props: {
    currentPage: "shares";
    count: number;
    shares: IShare[];
  };
}

export async function fetchShares(offset?: number) {
  const path = `/shares`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
