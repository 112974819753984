import { APIError } from "#lib/api";
import { HTTP_STATUS } from "#lib/http";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { Preformatted } from "#components/formatting";

interface IProps {
  routePathPattern: string;
  error: APIError;
}

export function APIErrorView({ routePathPattern, error }: IProps) {
  const { name, message, request, response } = error;
  const errorOrigin =
    response.status >= HTTP_STATUS.INTERNAL_SERVER_ERROR ? "Server" : "Client";

  return (
    <DescriptionList>
      <DescriptionSection
        dKey="Originated from"
        dValue={<Preformatted>{errorOrigin}</Preformatted>}
        isHorizontal
      />

      <DescriptionSection
        dKey="Route"
        dValue={<Preformatted>{routePathPattern}</Preformatted>}
      />

      <DescriptionSection
        dKey="Name"
        dValue={<Preformatted>{name}</Preformatted>}
        isHorizontal
      />

      <DescriptionSection
        dKey="Message"
        dValue={<Preformatted>{message}</Preformatted>}
      />

      <DescriptionSection
        dKey="Request"
        dValue={
          <DescriptionList>
            <DescriptionSection
              dKey="URL"
              dValue={<Preformatted>{request.url}</Preformatted>}
            />

            <DescriptionSection
              dKey="Method"
              dValue={<Preformatted>{request.method}</Preformatted>}
              isHorizontal
            />
          </DescriptionList>
        }
      />

      <DescriptionSection
        dKey="Response"
        dValue={
          <DescriptionList>
            {request.url !== response.url && (
              <DescriptionSection
                dKey="URL"
                dValue={<Preformatted>{response.url}</Preformatted>}
              />
            )}

            <DescriptionSection
              dKey="Is okay?"
              dValue={!response.ok ? <>No</> : <>Yes</>}
              isHorizontal
            />

            <DescriptionSection
              dKey="Status code"
              dValue={<Preformatted>{response.status}</Preformatted>}
              isHorizontal
            />

            {response.statusText && (
              <DescriptionSection
                dKey="Status text"
                dValue={<Preformatted>{response.statusText}</Preformatted>}
              />
            )}

            <DescriptionSection
              dKey="Type"
              dValue={<Preformatted>{response.type}</Preformatted>}
              isHorizontal
            />
          </DescriptionList>
        }
      />
    </DescriptionList>
  );
}
