import clsx from "clsx";

interface IImageProps extends IImageBaseProps {
  className?: string;
}

interface IImageBackgroundProps extends IImageBaseProps {
  className?: string;
}

export interface IImageBaseProps {
  src: string;
  srcset?: string;
  isLazy?: boolean;
  alt?: string;
}

export function Image({ src, srcset, isLazy, alt, className }: IImageProps) {
  return (
    <span className={clsx("fancy-image", className)}>
      <ImageBase src={src} srcset={srcset} isLazy={isLazy} alt={alt} />
    </span>
  );
}

export function ImageBackground({
  src,
  srcset,
  isLazy,
  alt,
  className,
}: IImageBackgroundProps) {
  return (
    <div className={clsx("fancy-image", "fancy-image--background", className)}>
      <ImageBase src={src} srcset={srcset} isLazy={isLazy} alt={alt} />
    </div>
  );
}

export function ImageBase({
  src,
  srcset,
  isLazy = true,
  alt,
}: IImageBaseProps) {
  return (
    <picture className="fancy-image__picture">
      <img
        className="fancy-image__image"
        src={src}
        alt={alt}
        srcSet={srcset}
        loading={isLazy ? "lazy" : "eager"}
      />
    </picture>
  );
}
