import { PageSkeleton } from "#components/pages";

export function Compliance2257Page() {
  const title = "18 U.S.C. 2257 Compliance Statement";
  const heading = "18 U.S.C. 2257 Compliance Statement";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <p>
        Kemono is not a producer (whether primary or secondary as defined in 18
        U.S.C. 2257) of any of the content found on this website. The website's
        activities, with respect to such content, are limited to the
        transmission, storage, retrieval, and/or hosting of content on behalf of
        third party users.
      </p>

      <p>
        Please direct any requests you may have regarding 2257 records in
        relation to any content found on Kemono directly to the respective
        uploader, artist, or producer of said content.
      </p>

      <p>
        Kemono abides by the following procedures regarding uploaded content to
        ensure compliance:
      </p>

      <ul>
        <li>
          Requiring all users to be over 18 years old to use the site, register
          an account, or upload content.
        </li>
        <li>
          Prohibiting the upload of any photographs or videos of any real person
          who is or appears to be under the age of 18.
        </li>
        <li>
          Moderating all uploaded content and expeditiously removing any content
          found to be in violation of these policies.
        </li>
      </ul>

      <p>
        For further assistance, or for any questions regarding this notice,
        please <a href="/contact">contact us</a>.
      </p>
    </PageSkeleton>
  );
}
