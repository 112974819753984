import { InternalURL } from "./internal-url";

export function createDMsPageURL(offset?: number, query?: string) {
  const path = "/dms";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (query) {
    params.set("q", String(query));
  }

  return new InternalURL(path, params);
}
