import { isRegisteredAccount } from "#entities/account";
import {
  LoaderFunction,
  LoaderFunctionArgs,
  ActionFunction,
} from "react-router-dom";

export function createAccountPageLoader(
  loader?: LoaderFunction
): LoaderFunction {
  return async (args: LoaderFunctionArgs) => {
    const isRegistered = await isRegisteredAccount();

    if (!isRegistered) {
      throw new Error("You must be registered to access this page.");
    }

    if (!loader) {
      return null;
    }

    return await loader(args);
  };
}

export async function validateAccountPageAction(
  ...args: Parameters<ActionFunction>
): Promise<void> {
  const isRegistered = await isRegisteredAccount();

  if (!isRegistered) {
    throw new Error("You must be registered to access this page.");
  }
}
