import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { createArchiveFileURL } from "#lib/urls";
import { fetchArchiveFile, fetchSetArchiveFilePassword } from "#api/files";
import { PageSkeleton } from "#components/pages";
import { IArchiveFile } from "#entities/files";

interface IProps {
  archive: IArchiveFile;
  isFileServingEnabled: boolean;
}

export function ArchiveFilePage() {
  const { archive, isFileServingEnabled } = useLoaderData() as IProps;
  const { file, file_list, password } = archive;
  const title = "Archive files";
  const heading = "Archive Files";

  return (
    <PageSkeleton name="archives" title={title} heading={heading}>
      <section id="password">
        {archive.password ? (
          <>Archive password: {archive.password}</>
        ) : archive.password === "" ? (
          <>
            Archive needs password, but none was provided.{" "}
            <a
              id="password-input"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              // TODO: a proper form
              onClick={async (event) => {
                event.preventDefault();
                const password = prompt("input password");

                if (!password) {
                  return;
                }

                const encodedPassword = encodeURIComponent(password);

                try {
                  await fetchSetArchiveFilePassword(file.hash, encodedPassword);

                  location.reload();
                } catch (error) {
                  console.error(error);
                  alert("Invalid password");
                }
              }}
            >
              Click to input
            </a>
          </>
        ) : undefined}
      </section>

      {file_list.length === 0 ? (
        <>Archive is empty or missing password.</>
      ) : (
        file_list.map((fileName) =>
          !isFileServingEnabled ? (
            <>
              {fileName}
              <br />
            </>
          ) : password ? (
            <>
              <a
                href={String(
                  createArchiveFileURL(file.hash, file.ext, fileName, password)
                )}
              >
                {fileName}
              </a>
              <br />
            </>
          ) : (
            <>
              <a
                href={String(
                  createArchiveFileURL(file.hash, file.ext, fileName)
                )}
              >
                {fileName}
              </a>
              <br />
            </>
          )
        )
      )}
    </PageSkeleton>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<IProps> {
  const fileHash = params.file_hash?.trim();
  if (!fileHash) {
    throw new Error("File hash is required.");
  }

  const { archive, file_serving_enabled } = await fetchArchiveFile(fileHash);

  if (!archive) {
    throw new Error("Archive not found.");
  }

  return {
    archive,
    isFileServingEnabled: file_serving_enabled,
  };
}
