import { InternalURL } from "./internal-url";

interface ICreateProfileURLArg {
  service: string;
  profileID: string;
  offset?: number;
  query?: string;
  tags?: string[];
}

export function createProfilePageURL({
  service,
  profileID,
  offset,
  query,
  tags,
}: ICreateProfileURLArg) {
  const segment = service === "discord" ? "server" : "user";
  const path = `/${service}/${segment}/${profileID}`;
  const searchParams = new URLSearchParams();

  if (offset) {
    searchParams.set("o", String(offset));
  }

  if (query) {
    searchParams.set("q", query);
  }

  if (tags) {
    for (const tag of tags) {
      searchParams.set("tag", tag);
    }
  }

  return new InternalURL(path, searchParams);
}

export function createProfileFancardsURL(service: string, profileID: string) {
  const path = `/${service}/user/${profileID}/fancards`;

  return new InternalURL(path);
}

export function createProfileAnnouncementsURL(
  service: string,
  profileID: string
) {
  return new InternalURL(`/${service}/user/${profileID}/announcements`);
}

export function createProfileTagsURL(service: string, profileID: string) {
  return new InternalURL(`/${service}/user/${profileID}/tags`);
}

export function createProfileTagURL(
  service: string,
  profileID: string,
  tag: string
) {
  const path = `/${service}/user/${profileID}`;
  const params = new URLSearchParams([["tag", tag]]);

  return new InternalURL(path, params);
}

export function createProfileDMsURL(service: string, profileID: string) {
  return new InternalURL(`/${service}/user/${profileID}/dms`);
}

export function createProfileSharesURL(service: string, profileID: string) {
  return new InternalURL(`/${service}/user/${profileID}/shares`);
}

export function createProfileLinksURL(service: string, profileID: string) {
  return new InternalURL(`/${service}/user/${profileID}/links`);
}

export function createProfileNewLinksPageURL(
  service: string,
  profileID: string
) {
  return new InternalURL(`/account/${service}/user/${profileID}/links/new`);
}

export function createProfilesSharesPageURL(
  service: string,
  profileID: string,
  offset?: number
) {
  const path = `/${service}/user/${profileID}/shares`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  return new InternalURL(path, params);
}

export function createProfileTagsPageURL(service: string, profileID: string) {
  const path = `/${service}/user/${profileID}/tags`;

  return new InternalURL(path)
}

export function createDiscordServerPageURL(serverID: string) {
  const path = `/discord/server/${serverID}`;

  return new InternalURL(path);
}

export function createDiscordChannelPageURL(
  serverID: string,
  channelID: string,
  offset?: number
) {
  const path = `/discord/server/${serverID}/${channelID}`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  return new InternalURL(path, params);
}
