import { InternalURL } from "./internal-url";

export function createArtistsPageURL(
  offset?: number,
  query?: string,
  service?: string,
  sortBy?: string,
  order?: string
): InternalURL {
  const path = "/artists";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (query) {
    params.set("q", query);
  }

  if (service) {
    params.set("service", service);
  }

  if (sortBy) {
    params.set("sort_by", sortBy);
  }

  if (order) {
    params.set("order", order);
  }

  return new InternalURL(path, params);
}

export function createArtistsUpdatedPageURL(offset?: number): InternalURL {
  const path = "/artists/updated";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  return new InternalURL(path, params);
}
