import { apiFetch } from "../fetch";

interface IResult {
  service: string;
  artist_id: string;
  post_id: string;
}

export async function fetchRandomPost() {
  const path = `/posts/random`;

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
