import {
  ActionFunctionArgs,
  redirect,
  useSearchParams,
} from "react-router-dom";
import { PageSkeleton } from "#components/pages";
import { KemonoLink } from "#components/links";
import { createArtistsPageURL, createRegistrationPageURL } from "#lib/urls";
import { FormRouter, FormSection } from "#components/forms";
import { loginAccount } from "#entities/account";

export function AccountLoginPage() {
  const [searchParams] = useSearchParams();
  const title = "Login";
  const heading = "Login";
  const location =
    searchParams.get("location")?.trim() ?? String(createArtistsPageURL());

  return (
    <PageSkeleton name="login" title={title} heading={heading}>
      <p className="site-section__register-cta">
        Don't have an account?{" "}
        <KemonoLink url={String(createRegistrationPageURL(location))}>
          Register!
        </KemonoLink>{" "}
        Your favorites will automatically be saved.
      </p>

      <FormRouter
        id="login_form"
        className="form"
        method="POST"
        submitButton={(state) => "Login"}
      >
        <input type="hidden" name="location" defaultValue={location} />

        <FormSection>
          <label className="form__label" htmlFor="old-username">
            Username:
          </label>
          <input
            id="old-username"
            className="form__input"
            type="text"
            name="username"
          />
        </FormSection>

        <FormSection>
          <label className="form__label" htmlFor="old-password">
            Password:
          </label>
          <input
            id="old-password"
            className="form__input"
            type="password"
            name="password"
            autoComplete="current-password"
          />
        </FormSection>
      </FormRouter>
    </PageSkeleton>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  try {
    if (request.method !== "POST") {
      throw new Error(`Unknown method "${request.method}".`);
    }

    const data = await request.formData();

    const location = data.get("location") as string;
    const username = data.get("username") as string | null;
    {
      if (!username) {
        throw new Error(`Username is required.`);
      }
    }

    const password = data.get("password") as string | null;
    {
      if (!password) {
        throw new Error(`Password is required.`);
      }
    }

    await loginAccount(username, password);

    return redirect(location);
  } catch (error) {
    return error;
  }
}
