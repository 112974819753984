import { ensureAPIError } from "#lib/api";
import { HTTP_STATUS } from "#lib/http";
import { apiFetch } from "../fetch";

export async function flagPost(
  service: string,
  profileID: string,
  postID: string
) {
  const path = `/${service}/user/${profileID}/post/${postID}/flag`;

  try {
    await apiFetch(path, { method: "POST" });

    return true;
  } catch (error) {
    ensureAPIError(error);

    if (error.response.status !== HTTP_STATUS.CONFLICT) {
      throw error;
    }

    return true;
  }
}
