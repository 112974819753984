import { redirect } from "react-router-dom";
import { createPostURL } from "#lib/urls";
import { fetchRandomPost } from "#api/posts";

export async function loader() {
  const { service, artist_id, post_id } = await fetchRandomPost();
  const url = String(createPostURL(service, artist_id, post_id));

  return redirect(url);
}
