import clsx from "clsx";
import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import { ARTISTS_OR_CREATORS_LOWERCASE } from "#env/derived-vars";
import { createArtistsUpdatedPageURL } from "#lib/urls";
import { parseOffset } from "#lib/pagination";
import { PageSkeleton } from "#components/pages";
import { FooterAd, HeaderAd, SliderAd } from "#components/ads";
import { Paginator } from "#components/pagination";
import { CardList, ArtistCard } from "#components/cards";
import { getArtists } from "#entities/profiles";

interface IProps {
  results: Awaited<ReturnType<typeof getArtists>>["artists"];
  offset: number;
  count: number;
}

export function ArtistsUpdatedPage() {
  const { results, count, offset } = useLoaderData() as IProps;
  const title = "Latest cached updated artists";
  const heading = "Latest Cached Updated Artists";

  return (
    <PageSkeleton name="artists" title={title} heading={heading}>
      <SliderAd />

      <div className="paginator" id="paginator-top">
        <Paginator
          count={count}
          offset={offset}
          constructURL={(offset) => {
            const url = createArtistsUpdatedPageURL(offset);

            return String(url);
          }}
        />
      </div>

      <HeaderAd />

      <CardList layout="phone">
        {results.length === 0 ? (
          <p className={clsx("subtitle", "card-list__item--no-results")}>
            No {ARTISTS_OR_CREATORS_LOWERCASE} found for your query.
          </p>
        ) : (
          results.map((artist) => (
            <ArtistCard
              key={`${artist.service}-${artist.id}`}
              artist={artist}
              isUpdated
              isFavorite={artist.isFavourite}
              singleOf="favorite"
              pluralOf="favorites"
            />
          ))
        )}
      </CardList>

      <div className="paginator" id="paginator-bottom">
        <Paginator
          count={count}
          offset={offset}
          constructURL={(offset) => {
            const url = createArtistsUpdatedPageURL(offset);

            return String(url);
          }}
        />
      </div>

      <FooterAd />
    </PageSkeleton>
  );
}

export async function loader({ request }: LoaderFunctionArgs): Promise<IProps> {
  const searchParams = new URL(request.url).searchParams;
  const sort_by = "updated";

  let offset: IProps["offset"] = 0;
  {
    const inputOffset = searchParams.get("o")?.trim();

    if (inputOffset) {
      offset = parseOffset(inputOffset);
    }
  }

  const { artists, count } = await getArtists({ offset, sort_by });

  return {
    results: artists,
    count,
    offset,
  };
}
