import { InvalidErrorType } from "#lib/errors";

interface IAPIError extends Error {
  request: Request;
  response: Response;
}

interface IAPIErrorOptions extends ErrorOptions {
  request: Request;
  response: Response;
}

export class APIError extends Error implements IAPIError {
  request: Request;
  response: Response;

  constructor(message: string, options: IAPIErrorOptions) {
    super(message);

    this.request = options.request;
    this.response = options.response;
  }
}

export function isAPIError(input: unknown): input is APIError {
  return input instanceof APIError;
}

export function ensureAPIError(input: unknown): asserts input is APIError {
  if (!isAPIError(input)) {
    throw new InvalidErrorType(input);
  }
}
