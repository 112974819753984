import clsx from "clsx";
import { ReactNode } from "react";

interface ICardProps {
  className?: string;
  children?: ReactNode;
}

interface ICardHeaderProps {
  className?: string;
  children?: ReactNode;
}

interface ICardBodyProps {
  className?: string;
  children?: ReactNode;
}

interface ICardFooterProps {
  className?: string;
  children?: ReactNode;
}

export function Card({ className, children }: ICardProps) {
  return <article className={clsx("card", className)}>{children}</article>;
}
export function CardHeader({ className, children }: ICardHeaderProps) {
  return (
    <header className={clsx("card__header", className)}>{children}</header>
  );
}
export function CardBody({ className, children }: ICardBodyProps) {
  return (
    <section className={clsx("card__body", className)}>{children}</section>
  );
}
export function CardFooter({ className, children }: ICardFooterProps) {
  return (
    <footer className={clsx("card__footer", className)}>{children}</footer>
  );
}
