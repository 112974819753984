import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import {
  createProfileLinkRequestsPageURL,
  createProfilePageURL,
} from "#lib/urls";
import {
  fetchApproveLinkRequest,
  fetchProfileLinkRequests,
  fetchRejectLinkRequest,
} from "#api/account/moderator";
import { PageSkeleton, createAccountPageLoader } from "#components/pages";
import { IProfileLinkRequest } from "#entities/account";
import { paysites } from "#entities/paysites";

interface IProps {
  linkRequests: IProfileLinkRequest[];
}

export function ProfileLinkRequestsPage() {
  const { linkRequests } = useLoaderData() as IProps;
  const title = "Profile links requests";
  const heading = "Profile Links Requests";

  return (
    <PageSkeleton
      name="moderator-creator-links"
      title={title}
      heading={heading}
    >
      <section id="card-list">
        {linkRequests.length === 0 ? (
          <p>No pending requests. Yay!</p>
        ) : (
          linkRequests.map((linkRequest) => (
            <ProfileLinkRequest
              key={linkRequest.id}
              linkRequest={linkRequest}
            />
          ))
        )}
      </section>
    </PageSkeleton>
  );
}

interface IProfileLinkRequestProps {
  linkRequest: IProfileLinkRequest;
}

function ProfileLinkRequest({ linkRequest }: IProfileLinkRequestProps) {
  const navigate = useNavigate();
  const { id, requester, from_creator, to_creator, reason } = linkRequest;
  const fromSite = paysites[from_creator.service];
  const toSite = paysites[to_creator.service];
  const fromURL = String(
    createProfilePageURL({
      service: from_creator.service,
      profileID: from_creator.id,
    })
  );
  const toURL = String(
    createProfilePageURL({
      service: to_creator.service,
      profileID: to_creator.id,
    })
  );

  return (
    <article
      id={`link-request-${id}`}
      className="link-request-card"
      data-id={id}
    >
      <section className="from">
        <h2>Profiles:</h2>
        <span>
          <span>{fromSite.title}</span>: <span>{from_creator.name}</span>
          <a href={fromURL}>»</a>
        </span>
      </section>

      <section className="to">
        <span>
          <span>{toSite.title}</span>: <span>{to_creator.name}</span>
          <a href={toURL}>»</a>
        </span>
      </section>

      <section className="reason">
        <h2>Reason:</h2>
        <span className="reason">{reason ?? <i>not provided</i>}</span>
      </section>
      <section className="user">
        <h6>User:</h6>
        <div>
          <span>{requester.username}</span>(<span>{requester.role}</span>)
        </div>
      </section>

      <section className="control">
        <button
          className="reject"
          onClick={async () => {
            await fetchRejectLinkRequest(id);

            navigate(String(createProfileLinkRequestsPageURL()), {
              replace: true,
            });
          }}
        >
          ✗
        </button>
        <button
          className="approve"
          onClick={async () => {
            await fetchApproveLinkRequest(id);

            navigate(String(createProfileLinkRequestsPageURL()), {
              replace: true,
            });
          }}
        >
          ✓
        </button>
      </section>
    </article>
  );
}

export const loader = createAccountPageLoader(
  async function loader({}: LoaderFunctionArgs): Promise<IProps> {
    const linkRequests = await fetchProfileLinkRequests();

    return { linkRequests };
  }
);
