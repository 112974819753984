import { IFavouriteArtist } from "#entities/account";
import { apiFetch } from "../../fetch";

export async function fetchFavouriteProfiles() {
  const path = `/account/favorites`;
  const params = new URLSearchParams([["type", "artist"]]);

  const data = await apiFetch<IFavouriteArtist[]>(
    path,
    { method: "GET" },
    params
  );

  return data;
}
