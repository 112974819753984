import { LoaderFunctionArgs, redirect, useLoaderData } from "react-router-dom";
import { createDiscordServerPageURL, createProfilePageURL } from "#lib/urls";
import { parseOffset } from "#lib/pagination";
import { ElementType } from "#lib/types";
import { fetchProfilePosts } from "#api/profiles";
import { FooterAd, SliderAd } from "#components/ads";
import { Paginator } from "#components/pagination";
import { CardList, PostCard } from "#components/cards";
import { ProfilePageSkeleton } from "#components/pages";
import { FormRouter } from "#components/forms";
import {
  ProfileHeader,
  Tabs,
  IArtistDetails,
  getArtist,
} from "#entities/profiles";
import { paysites, validatePaysite } from "#entities/paysites";
import { IPost } from "#entities/posts";
import { findFavouritePosts } from "#entities/account";

interface IProps {
  profile: IArtistDetails;
  postsData?: {
    count: number;
    offset?: number;
    posts: (IPost & { isFavourite: boolean })[];
  };

  query?: string;
  tags?: string[];
  dmCount?: number;
  hasLinks?: boolean;
}

export function ProfilePage() {
  const { profile, postsData, query, tags, dmCount, hasLinks } =
    useLoaderData() as IProps;
  const { service, id, name } = profile;
  const paysite = paysites[service];
  const title = `Posts of "${name}" from "${paysite.title}"`;

  return (
    <ProfilePageSkeleton name="user" title={title} profile={profile}>
      <SliderAd />

      <ProfileHeader service={service} profileID={id} profileName={name} />

      <div className="paginator" id="paginator-top">
        <Tabs
          currentPage="posts"
          service={service}
          artistID={id}
          dmCount={dmCount}
          hasLinks={hasLinks}
        />

        {!(postsData && (postsData?.count !== 0 || query)) ? undefined : (
          <>
            <Paginator
              offset={postsData.offset}
              count={postsData.count}
              constructURL={(offset) =>
                String(
                  createProfilePageURL({
                    service,
                    profileID: id,
                    offset,
                    query,
                    tags,
                  })
                )
              }
            />

            <FormRouter method="GET">
              <input
                id="q"
                className="search-input"
                type="text"
                name="q"
                autoComplete="off"
                defaultValue={query}
                minLength={2}
                placeholder={"search for posts..."}
              />
              {/* TODO: rewrite this into a proper form */}
              <input type="submit" style={{ display: "none" }} />
            </FormRouter>
          </>
        )}
      </div>

      {!postsData ? (
        <div className="no-results">
          <h2 className="site-section__subheading">
            Nobody here but us chickens!
          </h2>
          <p className="subtitle">There are no posts for your query.</p>
        </div>
      ) : (
        <>
          <CardList>
            {postsData.posts.map((post) => (
              <PostCard
                key={`${post.id}-${post.service}-${post.user}`}
                post={post}
                isServiceIconsDisabled={true}
                isFavourite={post.isFavourite}
              />
            ))}
          </CardList>

          <FooterAd />

          <div className="paginator" id="paginator-bottom">
            <Paginator
              offset={postsData.offset}
              count={postsData.count}
              constructURL={(offset) =>
                String(
                  createProfilePageURL({
                    service,
                    profileID: id,
                    offset,
                    query,
                    tags,
                  })
                )
              }
            />
          </div>
        </>
      )}
    </ProfilePageSkeleton>
  );
}

export async function loader({
  params,
  request,
}: LoaderFunctionArgs): Promise<IProps | Response> {
  const searchParams = new URL(request.url).searchParams;
  const service = params.service?.trim();
  {
    if (!service) {
      throw new Error("Service name is required.");
    }

    validatePaysite(service);
  }

  const profileID = params.creator_id?.trim();
  {
    if (!profileID) {
      throw new Error("Profile ID is required.");
    }
  }

  if (service === "discord") {
    return redirect(String(createDiscordServerPageURL(profileID)));
  }

  let offset: number | undefined;
  {
    const inputValue = searchParams.get("o")?.trim();

    if (inputValue) {
      offset = parseOffset(inputValue);
    }
  }

  let query: string | undefined = undefined;
  {
    const inputQuery = searchParams.get("q")?.trim();

    if (inputQuery) {
      query = inputQuery;
    }
  }

  const tags = searchParams.getAll("tag");

  const profile = await getArtist(service, profileID);
  const { props, results: posts } = await fetchProfilePosts(
    service,
    profileID,
    offset,
    query,
    tags
  );
  const { count, dm_count, has_links } = props;
  const hasLinks = !has_links || has_links === "0" ? false : true;
  const favPostData = await findFavouritePosts(
    posts.map(({ service, user, id }) => {
      return {
        service,
        user,
        id,
      };
    })
  );
  const finalPosts = posts.map<
    ElementType<Required<IProps>["postsData"]["posts"]>
  >((post) => {
    const match = favPostData.find(
      ({ service, user, id }) =>
        service === post.service && user === post.user && id === post.id
    );

    return { ...post, isFavourite: !match ? false : true };
  });

  return {
    profile,
    query,
    tags,
    postsData: {
      count,
      offset,
      posts: finalPosts,
    },
    dmCount: dm_count,
    hasLinks,
  };
}
