interface IInvalidErrorType extends Error {
  payload: unknown;
}

/**
 * An error for when the value thrown is not a subclass of `Error` class.
 */
export class InvalidErrorType extends Error implements IInvalidErrorType {
  payload: unknown;

  constructor(payload: unknown) {
    super("Invalid input error type.");

    this.payload = payload;
  }
}
