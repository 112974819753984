import { IPaySite } from "./types";


export const paysites: Record<string, IPaySite> = {
  patreon: {
    title: "Patreon",
    color: "#fa5742",
    user: {
      profile: (userID) => `https://www.patreon.com/user?u=${userID}`,
    },
    post: {},
  },
  fanbox: {
    title: "Pixiv Fanbox",
    color: "#2c333c",
    user: {
      profile: (userID) => `https://www.pixiv.net/fanbox/creator/${userID}`,
    },
    post: {},
  },
  subscribestar: {
    title: "SubscribeStar",
    color: "#009688",
    user: {
      profile: (userID) => `https://subscribestar.adult/${userID}`,
    },
    post: {},
  },
  gumroad: {
    title: "Gumroad",
    color: "#2b9fa4",
    user: {
      profile: (userID) => `https://gumroad.com/${userID}`,
    },
    post: {},
  },
  discord: {
    title: "Discord",
    color: "#5165f6",
    user: {
      profile: (userID) => ``,
    },
    post: {},
  },
  dlsite: {
    title: "DLsite",
    color: "#052a83",
    user: {
      profile: (userID) => `https://www.dlsite.com/eng/circle/profile/=/maker_id/${userID}`,
    },
    post: {},
  },
  fantia: {
    title: "Fantia",
    color: "#e1097f",
    user: {
      profile: (userID) => `https://fantia.jp/fanclubs/${userID}`,
    },
    post: {},
  },
  boosty: {
    title: "Boosty",
    color: "#fd6035",
    user: {
      profile: (userID) => `https://boosty.to/${userID}`,
    },
    post: {},
  },
  afdian: {
    title: "Afdian",
    color: "#9169df",
    user: {
      profile: (userID) => ``,
    },
    post: {},
  },
  fansly: {
    title: "Fansly",
    color: "#2399f7",
    user: {
      profile: (userID) => `https://fansly.com/${userID}`,
    },
    post: {},
  },
  onlyfans: {
    title: "OnlyFans",
    color: "#008ccf",
    user: {
      profile: (userID) => `https://onlyfans.com/${userID}`,
    },
    post: {},
  },
  candfans: {
    title: "CandFans",
    color: "#e8486c",
    user: {
      profile: (userID) => `https://candfans.jp/${userID}`,
    },
    post: {},
  },
};
