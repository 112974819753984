const periods = ["recent", "day", "week", "month"] as const;

export type IPopularPostsPeriod = (typeof periods)[number];

export function isValidPeriod(value: unknown): value is IPopularPostsPeriod {
  return periods.includes(value as IPopularPostsPeriod);
}

export function validatePeriod(
  value: unknown
): asserts value is IPopularPostsPeriod {
  if (!isValidPeriod(value)) {
    throw new Error(`"${value}" is not a valid period.`);
  }
}
