import { PageSkeleton } from "#components/pages";

export function ImporterOKPage() {
  return (
    <PageSkeleton name="" title="Success">
      <p>
        Your session key has been submitted to the server. Posts will be added
        soon. Thank you for contributing!
        <br />
        If you're having trouble with the importer, contact admin.
      </p>
    </PageSkeleton>
  );
}
