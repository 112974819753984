import { FormRouter, FormSection } from "#components/forms";

import styles from "./file_hash_search.module.scss";

interface IProps {
  id: string;
  hash?: string;
}

export function FileSearchForm({ id, hash }: IProps) {
  return (
    <FormRouter
      id={id}
      className={styles.block}
      method="POST"
      submitButton={() => <>Submit</>}
    >
      <FormSection>
        <label htmlFor="file">File:</label>
        <input id="file" type="file" name="file" />
      </FormSection>

      <FormSection>
        <span style={{ userSelect: "none" }}>
          <br />
          —or—
          <br />
        </span>
      </FormSection>

      <FormSection>
        <label htmlFor="hash">SHA256 hash:</label>
        <input id="hash" type="text" name="hash" defaultValue={hash} />
      </FormSection>
    </FormRouter>
  );
}
