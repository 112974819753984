import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { createProfileNewLinksPageURL } from "#lib/urls";
import { fetchArtistProfile, fetchProfileLinks } from "#api/profiles";
import { CardList, ArtistCard } from "#components/cards";
import { ProfilePageSkeleton } from "#components/pages";
import { ProfileHeader, Tabs, IArtistDetails } from "#entities/profiles";
import { validatePaysite } from "#entities/paysites";

interface IProps {
  service: string;
  profile: IArtistDetails;
  links: Awaited<ReturnType<typeof fetchProfileLinks>>;
}

export function ProfileLinksPage() {
  const { service, profile, links } = useLoaderData() as IProps;
  const title = "Linked profiles";
  const heading = "Linked Profiles";

  return (
    <ProfilePageSkeleton
      profile={profile}
      name={["user", "linked-account"]}
      title={title}
      heading={heading}
    >
      <ProfileHeader
        service={service}
        profileID={profile.id}
        profileName={profile.name}
      />

      <div className="paginator" id="paginator-top">
        <Tabs
          currentPage="linked_accounts"
          service={service}
          artistID={profile.id}
        />
      </div>

      <div id="add-new-link">
        <a href={String(createProfileNewLinksPageURL(service, profile.id))}>
          Link another account
        </a>
      </div>

      <CardList layout="phone">
        {links.length === 0 ? (
          <p className="subtitle card-list__item--no-results">
            No linked accounts found.
          </p>
        ) : (
          links.map((profile) => (
            <ArtistCard
              key={`${profile.service}-${profile.id}`}
              // TODO: a proper endpoint/component
              // @ts-expect-error the original template is using it this way
              artist={{
                ...profile,
                updated: String(
                  Math.round(new Date(profile.updated).getTime() / 1000)
                ),
                indexed: String(
                  Math.round(new Date(profile.indexed).getTime() / 1000)
                ),
              }}
            />
          ))
        )}
      </CardList>
    </ProfilePageSkeleton>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<IProps> {
  const service = params.service?.trim();
  {
    if (!service) {
      throw new Error("Service name is required.");
    }

    validatePaysite(service);
  }

  const profileID = params.creator_id?.trim();
  {
    if (!profileID) {
      throw new Error("Artist ID is required.");
    }
  }

  const profile = await fetchArtistProfile(service, profileID);
  const links = await fetchProfileLinks(service, profileID);

  return {
    service,
    profile,
    links,
  };
}
