import { Timestamp } from "#components/dates";
import { IAccount } from "#entities/account";

interface IProps {
  account: IAccount;
}

export function AccountCard({ account }: IProps) {
  const { id, username, role, created_at } = account;

  return (
    <article className="account-card" data-id={id}>
      <header className="account-card__header">
        <h2 className="account-card__name">{username}</h2>
      </header>

      <section className="account-card__body">
        <p className="account-card__role">
          Role: <span>{role}</span>
        </p>
      </section>

      <footer className="account-card__footer">
        <Timestamp time={created_at} />
      </footer>
    </article>
  );
}
