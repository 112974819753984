import { apiFetch } from "../../fetch";

export async function apiFavoriteProfile(service: string, profileID: string) {
  const path = `/favorites/creator/${service}/${profileID}`;

  await apiFetch(path, { method: "POST" });

  return true;
}

export async function apiUnfavoriteProfile(service: string, profileID: string) {
  const path = `/favorites/creator/${service}/${profileID}`;

  await apiFetch(path, { method: "DELETE" });

  return true;
}
