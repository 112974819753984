import { IShare } from "#entities/files";
import { createSharePageURL } from "#lib/urls";

interface IProps {
  share: IShare;
}

export function ShareCard({ share }: IProps) {
  const { id, name, description, added } = share;

  return (
    <article className="post-card" data-id={id}>
      <a href={String(createSharePageURL(id))}>
        <header className="post-card__header">{name}</header>

        <section className="post-card__body" tabIndex={0}>
          <div className="dm-card__content">
            <pre>{description}</pre>
          </div>
        </section>

        <footer className="post-card__footer">
          <div className="dm-card__added">Added: {added}</div>
        </footer>
      </a>
    </article>
  );
}
