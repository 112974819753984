import { ReactNode } from "react";
import {
  createProfileAnnouncementsURL,
  createProfileDMsURL,
  createProfileFancardsURL,
  createProfileLinksURL,
  createProfileSharesURL,
  createProfileTagsURL,
  createProfilePageURL,
} from "#lib/urls";
import { KemonoLink } from "#components/links";

interface IProps {
  currentPage:
    | "posts"
    | "fancards"
    | "announcements"
    | "tabs"
    | "dms"
    | "shares"
    | "linked_accounts";
  service: string;
  artistID: string;
  dmCount?: number;
  shareCount?: number;
  hasLinks?: boolean;
}

interface ITabProps {
  href: string;
  isActive?: boolean;
  children: ReactNode;
}

const announcementServices = ["fanbox", "patreon"];
const tabServices = ["patreon", "onlyfans", "fansly", "candfans"];

export function Tabs({
  currentPage,
  service,
  artistID,
  dmCount,
  shareCount,
  hasLinks,
}: IProps) {
  return (
    <ul className="tabs">
      <Tab
        href={String(createProfilePageURL({ service, profileID: artistID }))}
        isActive={currentPage === "posts"}
      >
        Posts
      </Tab>

      {service !== "fanbox" ? undefined : (
        <Tab
          href={String(createProfileFancardsURL(service, artistID))}
          isActive={currentPage === "fancards"}
        >
          Fancards
        </Tab>
      )}

      {!announcementServices.includes(service) ? undefined : (
        <Tab
          href={String(createProfileAnnouncementsURL(service, artistID))}
          isActive={currentPage === "announcements"}
        >
          Announcements
        </Tab>
      )}

      {/* TODO: fix the typos mismatch */}
      {!tabServices.includes(service) ? undefined : (
        <Tab
          href={String(createProfileTagsURL(service, artistID))}
          isActive={currentPage === "tabs"}
        >
          Tags
        </Tab>
      )}

      {!dmCount ? undefined : (
        <Tab
          href={String(createProfileDMsURL(service, artistID))}
          isActive={currentPage === "dms"}
        >
          DMs ({dmCount})
        </Tab>
      )}

      {!shareCount ? undefined : (
        <Tab
          href={String(createProfileSharesURL(service, artistID))}
          isActive={currentPage === "shares"}
        >
          Uploads ({shareCount})
        </Tab>
      )}

      <Tab
        href={String(createProfileLinksURL(service, artistID))}
        isActive={currentPage === "linked_accounts"}
      >
        {!hasLinks ? <>Linked Accounts</> : <>Linked Accounts (✔️)</>}
      </Tab>
    </ul>
  );
}

function Tab({ href, isActive, children }: ITabProps) {
  return (
    <li className="tab">
      <KemonoLink className={!isActive ? undefined : "active"} url={href}>
        {children}
      </KemonoLink>
    </li>
  );
}
