import { Card, CardBody, CardHeader } from "./base";

interface IProps {
  title?: string;
  message?: string;
}

export function NoResults({
  title = "Nobody here but us chickens!",
  message = "There are no items found.",
}: IProps) {
  return (
    <Card className="card--no-results">
      <CardHeader>
        <h2>{title}</h2>
      </CardHeader>

      <CardBody>{message}</CardBody>
    </Card>
  );
}
