import { createImporterStatusPageURL } from "#lib/urls";
import { Card, CardBody, CardFooter, CardHeader } from "#components/cards";
import { Timestamp } from "#components/dates";
import { paysites } from "#entities/paysites";
import { IAutoImportKey } from "#entities/account";

interface IProps {
  autoImportKey: IAutoImportKey;

  className?: string;
  importIDs?: { import_id: string }[];
}

export function AutoImportKeyCard({
  autoImportKey,
  importIDs,
  className,
}: IProps) {
  const { service, dead, added } = autoImportKey;
  const paysite = paysites[service];

  return (
    <Card className={className}>
      <CardHeader>
        <h2>{paysite.title}</h2>
      </CardHeader>

      <CardBody>
        <dl className="service-key__stats">
          <div className="service-key__stat">
            <dt>Status:</dt>
            {!dead ? (
              <dd className="service-key__status">Alive</dd>
            ) : (
              <dd className="service-key__status service-key__status--dead">
                Dead
              </dd>
            )}
          </div>
          <div className="service-key__stat">
            {importIDs && (
              <>
                <dt>Logs</dt>
                <ul>
                  {importIDs.map(({ import_id }) => (
                    <li>
                      <a href={String(createImporterStatusPageURL(import_id))}>
                        {import_id}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </dl>
      </CardBody>

      <CardFooter>
        <dl className="service-key__stats">
          <div className="service-key__stat">
            <dd>Added:</dd>
            <dt>
              <Timestamp time={added} />
            </dt>
          </div>
        </dl>
      </CardFooter>
    </Card>
  );
}
