import { PageSkeleton } from "#components/pages";

export function MatrixPage() {
  const title = "Matrix ecosystem";
  const heading = "Welcome to the Matrix ecosystem";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <ul>
        <li>
          The Instance can be found at the following url:{" "}
          <a href="https://matrix.kemono.su/">https://matrix.kemono.su/</a>
        </li>
        <li>The registration token is not 'kemono'</li>
        <li>The Federation domain is: kemono.su</li>
        <li>
          A "Spaces" + "E2EE" compatible client is needed for our homeserver,
          choose one from here{" "}
          <a href="https://matrix.org/ecosystem/clients/">
            https://matrix.org/ecosystem/clients/
          </a>
        </li>
        <li>
          This is not a file sharing service, files might get deleted at any
          moment.
        </li>
        <li>
          Be <i>reasonable</i>.
        </li>
        <li>
          Matrix homeserver powered by{" "}
          <a href="https://github.com/girlbossceo/conduwuit">
            conduwuit 🏳️‍⚧️ 💜 🦴
          </a>
        </li>
        <li>
          <b>One sentence is a lie.</b>
        </li>
      </ul>
    </PageSkeleton>
  );
}
