import { createProfileLinkRequestsPageURL } from "#lib/urls";
import { PageSkeleton, createAccountPageLoader } from "#components/pages";

export function ModeratorDashboardPage() {
  const title = "Moderator overview";
  const heading = "Moderator Overview";

  return (
    <PageSkeleton name="moderator-dashboard" title={title} heading={heading}>
      <nav>
        <ul>
          <li>
            <a href={String(createProfileLinkRequestsPageURL())}>
              Creator link requests
            </a>
          </li>
        </ul>
      </nav>
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader();
