import { useEffect, useRef } from "react";

/**
 * Stolen from
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<typeof callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(tick, delay);

      return () => clearInterval(id);
    }

    function tick() {
      savedCallback.current!();
    }
  }, [delay]);
}
