export class InternalURL extends URL {
  constructor(
    pathname: string,
    searchParams?: URLSearchParams,
    fragment?: string
  ) {
    // `URL` constructor requires a full origin
    // to be present in either of arguments
    // but in the context of DOM elements the relative URL is just fine
    // so we are doing some gymnastics in order not to depend
    // on browser context (does not exist on server)
    // or an env variable (not needed if the origin is the same).
    super(pathname, "https://example.com");

    if (searchParams && searchParams.size !== 0) {
      this.search = searchParams.toString();
    }

    if (fragment) {
      this.hash = fragment;
    }
  }

  toString(): string {
    const isParams = this.searchParams.size !== 0;

    if (isParams) {
      this.searchParams.sort();
    }

    const params = !isParams ? "" : this.search;
    const fragment = this.hash.slice(1).length === 0 ? "" : this.hash;

    return `${this.pathname}${params}${fragment}`;
  }

  toJSON(): string {
    return this.toString();
  }
}
