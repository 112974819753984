import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { createProfileTagURL } from "#lib/urls";
import { PageSkeleton } from "#components/pages";
import { getTags } from "#entities/tags";
import { paysites, validatePaysite } from "#entities/paysites";
import { ProfileHeader, Tabs } from "#entities/profiles";

interface IProps extends Awaited<ReturnType<typeof getTags>> {}

export function ProfileTagsPage() {
  const { service, artist, tags } = useLoaderData() as IProps;
  const paysite = paysites[service];
  const title = `Tags of "${artist.name}" from ${paysite.title}`;

  return (
    <PageSkeleton name="user" title={title}>
      <ProfileHeader
        service={service}
        profileID={artist.id}
        profileName={artist.name}
      />

      <div className="paginator" id="paginator-top">
        <Tabs currentPage="tabs" service={service} artistID={artist.id} />
      </div>

      <section id="tag-container">
        {tags.length === 0 ? (
          <div className="no-results">
            <h2 className="site-section__subheading">
              Nobody here but us chickens!
            </h2>
            <p className="subtitle">There are no tags for your query.</p>
          </div>
        ) : (
          tags.map((tag, index) => (
            <article key={tag.tag}>
              <a
                key={index}
                href={String(createProfileTagURL(service, artist.id, tag.tag))}
              >
                <span>{tag.tag}</span>
                <span>{tag.post_count}</span>
              </a>
            </article>
          ))
        )}
      </section>
    </PageSkeleton>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<IProps> {
  const service = params.service?.trim();
  {
    if (!service) {
      throw new Error("Service name is required.");
    }

    validatePaysite(service);
  }

  const profileID = params.creator_id?.trim();
  {
    if (!profileID) {
      throw new Error("Artist ID is required.");
    }
  }

  const result = await getTags(service, profileID);

  return result;
}
