import { ReactNode } from "react";
import { IBlockProps, createBlockComponent } from "#components/meta";

interface IProps extends IBlockProps<"details"> {
  summary: ReactNode;
}

export const Details = createBlockComponent(undefined, Component);

function Component({ summary, children, ...props }: IProps) {
  return (
    <details {...props}>
      <summary>{summary}</summary>
      {children && <div>{children}</div>}
    </details>
  );
}
