export const PAGINATION_LIMIT = 50;

export function parseOffset(offset: string | number, limit = PAGINATION_LIMIT) {
  const parsedOffset =
    typeof offset === "number" ? offset : parseInt(offset.trim(), 10);

  if (parsedOffset % limit !== 0) {
    throw new Error(`Offset ${offset} is not a multiple of ${limit}.`);
  }

  return parsedOffset;
}
