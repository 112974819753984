import { InternalURL } from "./internal-url";

export function createRegistrationPageURL(location: string) {
  const path = `/authentication/register`;
  const params = new URLSearchParams([["location", location]]);

  return new InternalURL(path, params);
}

export function createLoginPageURL(location: string) {
  const path = `/authentication/login`;
  const params = new URLSearchParams([["location", location]]);

  return new InternalURL(path, params);
}

export function createLogoutPageURL() {
  const path = `/authentication/logout`;

  return new InternalURL(path);
}
