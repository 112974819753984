import { IArtist } from "#entities/profiles";
import { IPost } from "#entities/posts";
import { apiFetch } from "../fetch";

interface IResult {
  props: {
    currentPage: "posts";
    id: string;
    service: string;
    name: string;
    count: number;
    limit: number;
    artist: IArtist;
    display_data: {
      service: string;
      href: string;
    };
    dm_count: number;
    share_count: number;
    has_links: "0" | "✔️";
  };

  base: Record<string, unknown>
  results: IPost[]
  result_previews: Record<string, unknown>[]
  result_atachments: Record<string, unknown>[]
  result_is_image: boolean[]
  disable_service_icons: true
}

export async function fetchProfilePosts(
  service: string,
  profileID: string,
  offset?: number,
  query?: string,
  tags?: string[]
) {
  const path = `/${service}/user/${profileID}/posts-legacy`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (query) {
    params.set("q", query);
  }

  if (tags && tags.length) {
    for (const tag of tags) {
      params.append("tag", tag);
    }
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
