import { useLocation } from "react-router-dom";
import { HEADER_AD, MIDDLE_AD, FOOTER_AD, SLIDER_AD } from "#env/env-vars";
import { DangerousContent } from "#components/dangerous-content";

export function HeaderAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !HEADER_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(HEADER_AD)}
      allowRerender
    />
  );
}

export function MiddleAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !MIDDLE_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(MIDDLE_AD)}
      allowRerender
    />
  );
}

export function FooterAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !FOOTER_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(FOOTER_AD)}
      allowRerender
    />
  );
}

export function SliderAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !SLIDER_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(SLIDER_AD)}
      allowRerender
    />
  );
}
