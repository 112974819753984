import { ActionFunctionArgs, redirect, useLoaderData } from "react-router-dom";
import { createAccountImportKeysPageURL } from "#lib/urls";
import {
  fetchAccountAutoImportKeys,
  fetchRevokeAutoImportKeys,
} from "#api/account/auto-import-keys";
import { IAutoImportKey } from "#entities/account";
import { CardList, NoResults } from "#components/cards";
import { FormRouter } from "#components/forms";
import {
  PageSkeleton,
  createAccountPageLoader,
  validateAccountPageAction,
} from "#components/pages";
import { AutoImportKeyCard } from "#entities/account";

interface IProps {
  autoImportKeys: IAutoImportKey[];
  importIDs: { import_id: string }[];
}

export function AccountAutoImportKeysPage() {
  const { autoImportKeys, importIDs } = useLoaderData() as IProps;
  const title = "Manage saved keys";
  const heading = "Stored service keys";
  const revokeFormID = "revoke-service-keys";

  return (
    <PageSkeleton name="account-keys" title={title} heading={heading}>
      <CardList>
        {autoImportKeys.length === 0 ? (
          <NoResults />
        ) : (
          autoImportKeys.map((autoImportKey, index) => (
            <div key={autoImportKey.id} className="form__section key__view">
              <input
                id={`key-${autoImportKey.id}`}
                className="form__input key__revoke-check"
                type="checkbox"
                name="revoke"
                value={autoImportKey.id}
                form={revokeFormID}
              />
              <div className="key__info">
                <AutoImportKeyCard
                  key={autoImportKey.id}
                  className="key__card"
                  autoImportKey={autoImportKey}
                  // @ts-expect-error TODO: look up actual shape
                  importIDs={importIDs[index]}
                />
                <label
                  className="form__label key__label"
                  htmlFor={`key-${autoImportKey.id}`}
                >
                  Revoke
                </label>
              </div>
            </div>
          ))
        )}
      </CardList>

      {autoImportKeys.length !== 0 && (
        <FormRouter
          id="revoke-service-keys"
          method="POST"
          submitButton={() => <>Revoke selected keys</>}
        />
      )}
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader(
  async function loader(): Promise<IProps> {
    const { props, import_ids } = await fetchAccountAutoImportKeys();
    const { service_keys } = props;

    return {
      autoImportKeys: service_keys,
      importIDs: import_ids,
    };
  }
);

export async function action(args: ActionFunctionArgs) {
  try {
    await validateAccountPageAction(args);

    const { request } = args;

    if (request.method !== "POST") {
      throw new Error(`Unknown method "${request.method}".`);
    }

    const data = await request.formData();

    const idsForRevocation = (data.getAll("revoke") as string[]).map((id) =>
      parseInt(id, 10)
    );

    if (idsForRevocation.length === 0) {
      throw new Error("At least one key must be selected for revocation.");
    }

    await fetchRevokeAutoImportKeys(idsForRevocation);

    return redirect(String(createAccountImportKeysPageURL()));
  } catch (error) {
    return error;
  }
}
