import clsx from "clsx";
import { ReactNode } from "react";
import { useClient } from "#hooks";
import { LoadingIcon } from "../loading/loading_icon";

interface IProps {
  time: string;
  isRelative?: boolean;
  className?: string;
  children?: ReactNode;
}

export function Timestamp({ time, isRelative, className, children }: IProps) {
  const isClient = useClient();

  return (
    <time className={clsx("timestamp", className)} dateTime={time}>
      {!isClient ? <LoadingIcon /> : children ?? time}
    </time>
  );
}
