import { apiFetch } from "../fetch";

export async function fetchRegisterAccount(
  userName: string,
  password: string,
  confirmPassword: string,
  favorites?: string
) {
  const path = `/authentication/register`;
  const body = {
    username: userName,
    password,
    confirm_password: confirmPassword,
    favorites_json: favorites,
  };

  const result = await apiFetch<true>(path, { method: "POST", body });

  return result;
}
