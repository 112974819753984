import { apiFetch } from "../../fetch";

export async function apiFavoritePost(
  service: string,
  profileID: string,
  postID: string
) {
  const path = `/favorites/post/${service}/${profileID}/${postID}`;

  await apiFetch(path, { method: "POST" });

  return true;
}

export async function apiUnfavoritePost(
  service: string,
  profileID: string,
  postID: string
) {
  const path = `/favorites/post/${service}/${profileID}/${postID}`;

  await apiFetch(path, { method: "DELETE" });

  return true;
}
