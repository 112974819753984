import { FOOTER_ITEMS, GIT_COMMIT_HASH } from "#env/env-vars";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { Preformatted } from "#components/formatting";

export function GlobalFooter() {
  return (
    <ul className="footer">
      {GIT_COMMIT_HASH && (
        <li>
          <DescriptionList>
            <DescriptionSection
              dKey="Version"
              dValue={<Preformatted>{GIT_COMMIT_HASH}</Preformatted>}
            />
          </DescriptionList>
        </li>
      )}
      {FOOTER_ITEMS?.map((item, index) => (
        <li key={index} dangerouslySetInnerHTML={{ __html: String(item) }}></li>
      ))}
    </ul>
  );
}
