import { IAccount } from "#entities/account";
import { apiFetch } from "../fetch";
import { ensureAPIError } from "#lib/api";
import { fetchAccount } from "../account/account";

export async function fetchLoginAccount(username: string, password: string) {
  const path = `/authentication/login`;
  const body = {
    username,
    password,
  };

  try {
    const result = await apiFetch<IAccount>(path, { method: "POST", body });

    return result;
  } catch (error) {
    ensureAPIError(error);

    // account is already logged in
    if (error.response.status !== 409) {
      throw error;
    }

    const result = await fetchAccount();

    return result.props.account;
  }
}
