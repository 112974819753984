import { useEffect, useState } from "react";
import clsx from "clsx";
import { flagPost } from "#api/posts";
import { LoadingIcon } from "#components/loading";
import { IPostActionsProps } from "./types";

interface IFlagButtonProps
  extends Pick<
    IPostActionsProps,
    "flagged" | "service" | "profileID" | "postID"
  > {}

/**
 * TODO: promptless flagging
 */
export function FlagButton({
  service,
  profileID,
  postID,
  flagged,
}: IFlagButtonProps) {
  const [isFlagged, switchFlag] = useState(Boolean(flagged));
  const [isFlagging, switchFlagging] = useState(false);

  useEffect(() => {
    switchFlag(Boolean(flagged));
  }, [service, profileID, postID, flagged]);

  async function handleFlagging() {
    const isConfirmed = confirm(
      "Are you sure you want to flag this post for reimport? Only do this if data in the post is broken/corrupted/incomplete.\nThis is not a deletion button."
    );

    if (!isConfirmed) {
      return;
    }

    try {
      switchFlagging(true);

      await flagPost(service, profileID, postID);

      switchFlag(true);
    } finally {
      switchFlagging(false);
    }
  }

  return !isFlagged ? (
    <button
      className={clsx("post__flag", isFlagging && "post__flag--loading")}
      type="button"
      disabled={isFlagging}
      onClick={handleFlagging}
    >
      <span className="post__flag-icon">
        {isFlagging ? <LoadingIcon /> : <>⚑</>}
      </span>
      <span>Flag</span>
    </button>
  ) : (
    <span className={clsx("post__flag", "post__flag--flagged")}>
      <span className="post__flag-icon">⚑</span>
      <span>Flagged</span>
    </span>
  );
}
