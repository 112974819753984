import { redirect } from "react-router-dom";
import { createProfilePageURL } from "#lib/urls";
import { fetchRandomArtist } from "#api/profiles";

export async function loader() {
  const { service, artist_id } = await fetchRandomArtist();
  const url = String(createProfilePageURL({ service, profileID: artist_id }));

  return redirect(url);
}
