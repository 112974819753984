export function mergeHeaders(
  firstHeaders: Headers,
  secondHeaders: Headers
): Headers {
  const resultHeaders = new Headers(firstHeaders);

  secondHeaders.forEach((value, key) => {
    resultHeaders.append(key, value);
  });

  return resultHeaders;
}
