import { useEffect } from "react";
import { PostHeader } from "./header";
import { PostBody } from "./body";
import { PostFooter } from "./footer";
import { IPostOverviewProps } from "./types";

import "fluid-player/src/css/fluidplayer.css";

export function PostOverview({
  post,
  profile,
  revisions,
  flagged,
  videos,
  attachments,
  previews,
  archives_enabled,
  comments,
  postTitle,
  paysite,
}: IPostOverviewProps) {
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", handleShowTagsButton);
    window.addEventListener("resize", handleShowTagsButton);

    return () => {
      document.removeEventListener("DOMContentLoaded", handleShowTagsButton);
      window.removeEventListener("resize", handleShowTagsButton);
    };
  }, []);

  function handleShowTagsButton() {
    addShowTagsButton();
  }

  return (
    <>
      <PostHeader
        post={post}
        profile={profile}
        postTitle={postTitle}
        paysite={paysite}
        revisions={revisions}
        flagged={flagged}
      />

      <PostBody
        post={post}
        videos={videos}
        attachments={attachments}
        previews={previews}
        archives_enabled={archives_enabled}
      />

      <PostFooter
        comments={comments}
        service={post.service}
        profileID={post.user}
        profileName={profile.name}
      />
    </>
  );
}

function addShowTagsButton() {
  let div = document.querySelector("#post-tags > div");

  if (document.getElementById("show-tag-overflow-button")) {
    // @ts-expect-error no fucking idea what it does
    document.getElementById("show-tag-overflow-button").remove();
  }
  // @ts-expect-error no fucking idea what it does

  if (div && div.offsetWidth < div.scrollWidth) {
    // tags overflow
    let button = document.createElement("a");
    button.href = "javascript:void 0";
    button.id = "show-tag-overflow-button";
    button.textContent = "Show all »";
    button.onclick = (e) => {
      if (div.classList.contains("show-overflow")) {
        div.classList.remove("show-overflow");
        button.textContent = "Show all»";
      } else {
        div.classList.add("show-overflow");
        button.textContent = "« Hide";
      }
    };
    // @ts-expect-error no fucking idea what it does
    div.parentElement.appendChild(button);
  }
}
