/**
 * Python [`range()`](https://docs.python.org/3/library/functions.html#func-range) but in javascript.
 */
export function createRange(start: number, stop: number, step = 1) {
  if (step === 0) {
    throw new RangeError("Step must not be equal to zero.");
  }

  const length = stop - start;
  let currentValue = start;
  // running `Array.fill()` because I don't remember off top of my head
  // if `Array.map()` iterates over sparse values or not
  const range = new Array(length).fill(null).map<number>(() => {
    const oldCurrentValue = currentValue;

    currentValue = currentValue + step;

    return oldCurrentValue;
  });

  return range;
}
