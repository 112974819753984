import clsx from "clsx";

import { IFuncComponent } from "./types";

interface IBaseProps {
  className?: string;
}

export function createBlockComponent<Props extends IBaseProps = IBaseProps>(
  blockClassName: { block?: string } | string | string[] | undefined,
  functionComponent: IFuncComponent<Props>
): typeof functionComponent {
  return (...args: Parameters<typeof functionComponent>) => {
    const [props, ref] = args;
    const blockClass =
      blockClassName === undefined
        ? undefined
        : typeof blockClassName === "string"
        ? blockClassName
        : Array.isArray(blockClassName)
        ? blockClassName
        : blockClassName.block;
    const baseClass = clsx(blockClass, props.className);

    return functionComponent({ ...props, className: baseClass }, ref);
  };
}
