import { apiFetch } from "../fetch";

interface IBody {
  session_key: string;
  service: string;
  auto_import?: string;
  save_session_key?: string;
  save_dms?: boolean;
  channel_ids?: string;
  "x-bc"?: string;
  auth_id?: string;
  user_agent?: string;
}

interface IResult {
  import_id: string;
}

export async function fetchCreateImport(input: IBody) {
  const path = `/importer/submit`;
  const body: IBody = input;

  const result = await apiFetch<IResult>(path, { method: "POST", body });

  return result;
}
