import {
  KEMONO_SITE,
  NODE_ENV,
  PAYSITE_LIST,
  ARTISTS_OR_CREATORS,
  API_SERVER_BASE_URL,
  API_SERVER_PORT,
} from "./env-vars";
import { IPaySite, paysites } from "#entities/paysites";

export const IS_DEVELOPMENT = NODE_ENV === "development";
export const SITE_HOSTNAME = new URL(KEMONO_SITE).hostname;
export const AVAILABLE_PAYSITES = PAYSITE_LIST.reduce<typeof paysites>(
  (availablePaysites, paysiteName) => {
    const paysite = paysites[paysiteName];
    availablePaysites[paysiteName] = paysite;

    return availablePaysites;
  },
  {}
);
export const AVAILABLE_PAYSITE_LIST = PAYSITE_LIST.map<
  IPaySite & { name: string }
>((paysiteName) => {
  return { ...AVAILABLE_PAYSITES[paysiteName], name: paysiteName };
});
export const ARTISTS_OR_CREATORS_LOWERCASE = ARTISTS_OR_CREATORS.toLowerCase();
export const API_SERVER_URL = !API_SERVER_BASE_URL
  ? ""
  : `${API_SERVER_BASE_URL}${!API_SERVER_PORT ? "" : `:${API_SERVER_PORT}`}`;
