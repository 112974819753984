const storageNames = [
  "favorites",
  "logged_in",
  "role",
  "favs",
  "post_favs",
  "has_pending_review_dms",
  "last_checked_has_pending_review_dms",
  "sidebar_state",
] as const;

export interface ILocalStorageSchema {
  favs: {
    service: string;
    id: string;
  }[];
  post_favs: {
    id: string;
    service: string;
    user: string;
  }[];
}

type ILocalStorageName = (typeof storageNames)[number];

export function getLocalStorageItem(name: ILocalStorageName) {
  return localStorage.getItem(name);
}

export function setLocalStorageItem(name: ILocalStorageName, value: string) {
  localStorage.setItem(name, value);
}

export function deleteLocalStorageItem(name: ILocalStorageName) {
  localStorage.removeItem(name);
}

export function isLocalStorageAvailable() {
  try {
    localStorage.setItem("__storage_test__", "__storage_test__");
    localStorage.removeItem("__storage_test__");
    return true;
  } catch (error) {
    return false;
  }
}
