import { IProfileLinkRequest } from "#entities/account";
import { apiFetch } from "../../fetch";

export async function fetchProfileLinkRequests() {
  const path = `/account/moderator/tasks/creator_links`;

  const linkRequests = await apiFetch<IProfileLinkRequest[]>(path, {
    method: "GET",
  });

  return linkRequests;
}

export async function fetchApproveLinkRequest(requestID: string) {
  const path = `/account/moderator/creator_link_requests/${requestID}/approve`;
  const resp = await apiFetch<{ response: "approved" }>(path, {
    method: "POST",
  });

  return resp;
}

export async function fetchRejectLinkRequest(requestID: string) {
  const path = `/account/moderator/creator_link_requests/${requestID}/reject`;
  const resp = await apiFetch<{ response: "rejected" }>(path, {
    method: "POST",
  });

  return resp;
}
