import {
  IComment,
  IPost,
  IPostAttachment,
  IPostPreview,
  IPostRevision,
  IPostVideo,
} from "#entities/posts";
import { apiFetch } from "../fetch";

interface IResult {
  post: IPost;
  attachments: IPostAttachment[];
  previews: IPostPreview[];
  videos: IPostVideo[];
  props: {
    service: string;
    flagged?: 0;
    revisions: [number, IPost][];
  };
}

export async function fetchPost(
  service: string,
  profileID: string,
  postID: string
) {
  const path = `/${service}/user/${profileID}/post/${postID}`;
  const ifModifiedDate = new Date();

  ifModifiedDate.setFullYear(ifModifiedDate.getFullYear() - 1);

  const headers = new Headers([
    ["If-Modified-Since", ifModifiedDate.toUTCString()],
  ]);
  const result = await apiFetch<IResult>(path, { method: "GET", headers });

  return result;
}

export async function fetchPostComments(
  service: string,
  profileID: string,
  postID: string
) {
  const path = `/${service}/user/${profileID}/post/${postID}/comments`;
  const ifModifiedDate = new Date();

  ifModifiedDate.setFullYear(ifModifiedDate.getFullYear() - 1);

  const headers = new Headers([
    ["If-Modified-Since", ifModifiedDate.toUTCString()],
  ]);
  const result = await apiFetch<IComment[]>(path, { method: "GET", headers });

  return result;
}

interface IPostData {
  service: string;
  artist_id: string;
  post_id: string;
}

export async function fetchPostData(service: string, postID: string) {
  const path = `/${service}/post/${postID}`;

  const result = await apiFetch<IPostData>(path, { method: "GET" });

  return result;
}
