import { apiFetch } from "../../fetch";

interface IBody {
  revoke: number[];
}

export async function fetchRevokeAutoImportKeys(keyIDs: number[]) {
  const path = `/account/keys`;
  const body: IBody = {
    revoke: keyIDs,
  };
  await apiFetch(path, { method: "POST", body });

  return true;
}
