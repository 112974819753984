import { INotification } from "#entities/account";
import { apiFetch } from "../fetch";

interface IResult {
  props: {
    currentPage: "account";
    notifications: INotification[];
  };
}

export async function fetchAccountNotifications() {
  const path = "/account/notifications";

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result.props;
}
