import { useLocation } from "react-router-dom";

/**
 * TODO: path pattern without circular reference
 * on the route config object.
 */
export function useRoutePathPattern(): string {
  const location = useLocation();

  return location.pathname;
}
