import { IArtist } from "#entities/profiles";
import { IApprovedDM } from "#entities/dms";
import { apiFetch } from "../fetch";

interface IResult {
  props: {
    id: string;
    service: string;
    artist: IArtist;
    display_data: {
      service: string;
      href: string;
    };

    share_count: number;
    dm_count: number;
    dms: IApprovedDM[];
    has_links: "✔️" | "0";
  };
}

export async function fetchProfileDMs(service: string, profileID: string) {
  const path = `/${service}/user/${profileID}/dms`;
  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
