import { IArtistWithFavs } from "#entities/profiles";
import { IS_DEVELOPMENT } from "#env/derived-vars";
import { apiFetch } from "../fetch";

export async function fetchProfiles(): Promise<IArtistWithFavs[]> {
  const path = IS_DEVELOPMENT ? "/creators" : "/creators.txt";
  const result = await apiFetch<IArtistWithFavs[]>(path, {
    method: "GET",
  });

  return result;
}
