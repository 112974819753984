import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface IClientContext {
  isClient: boolean;
}

const defaultContext: IClientContext = { isClient: false };
const ClientContext = createContext<IClientContext>(defaultContext);

interface IProps {
  children?: ReactNode;
}

export function ClientProvider({ children }: IProps) {
  const [isClient, switchIsClient] = useState(false);

  useEffect(() => {
    switchIsClient(true);
  }, []);

  return (
    <ClientContext.Provider value={{ isClient }}>
      {children}
    </ClientContext.Provider>
  );
}

export function useClient(): boolean {
  const { isClient } = useContext(ClientContext);

  return isClient;
}
