import { InternalURL } from "./internal-url";

export function createFileURL(hash: string, extension: string) {
  const path = `/${hash.slice(0, 2)}/${hash.slice(2, 4)}/${hash}.${extension}`;

  return new InternalURL(path);
}

export function createThumbnailURL(filePath: string) {
  const path = `/thumbnail/data${filePath}`;

  return new InternalURL(path);
}

export function createArchiveFileURL(
  archiveHash: string,
  archiveExtension: string,
  fileName: string,
  password?: string
) {
  const path = `/archive_files/${archiveHash}${archiveExtension}`;
  const params = new URLSearchParams([["file_name", fileName]]);

  if (password) {
    params.set("password", password);
  }

  return new InternalURL(path, params);
}

export function createFileSearchPageURL(hash?: string) {
  const path = `/search_hash`;
  const params = new URLSearchParams();

  if (hash) {
    params.set("hash", hash);
  }

  return new InternalURL(path, params);
}
