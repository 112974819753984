import { ARTISTS_OR_CREATORS, SITE_NAME } from "#env/env-vars";
import { PageSkeleton } from "#components/pages";

export function ImporterTutorialPage() {
  return (
    <PageSkeleton name="" title="How to get your session key">
      <h2>
        Patreon, Fanbox, SubscribeStar, Gumroad, DLsite, Fantia, Boosty, Afdian
      </h2>
      <p>
        {SITE_NAME} needs your session key in order to access posts from the{" "}
        {ARTISTS_OR_CREATORS.toLowerCase()} you are subscribed to.
      </p>
      <p>Below are the respective cookies for the supported paysites.</p>

      <ul>
        <li>
          For <em>Patreon,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>session_id</code>.
        </li>
        <li>
          For <em>Fanbox,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>FANBOXSESSID</code>.
        </li>
        <li>
          For <em>Gumroad,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>_gumroad_app_session</code>.
        </li>
        <li>
          For <em>SubscribeStar,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>_personalization_id</code>.
        </li>
        <li>
          For <em>DLsite,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>__DLsite_SID</code>.
        </li>
        <li>
          For <em>Fantia,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>_session_id</code>.
        </li>
        <li>
          For <em>Boosty,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>auth</code>.
        </li>
        <li>
          For <em>Afdian,</em> your session key is under{" "}
          <code style={{ userSelect: "all" }}>auth_token</code>.
        </li>
      </ul>

      <p>
        After going to the paysite you want to import and signing in, (
        <a href="https://patreon.com">Patreon</a>/
        <a href="https://www.fanbox.cc/">Fanbox</a>/
        <a href="https://gumroad.com/">Gumroad</a>/
        <a href="https://subscribestar.adult/">SubscribeStar</a>/
        <a href="https://play.dlsite.com/eng/#/library">DLsite English</a>/
        <a href="https://play.dlsite.com/#/library">DLsite Japan</a>/
        <a href="https://fantia.jp">Fantia</a>/
        <a href="https://boosty.to">Boosty</a>/
        <a href="https://afdian.net">Afdian</a>) you need to find where cookies
        are located in your browser.
      </p>

      <h3 id="chrome">Chrome</h3>
      <ul>
        <li>
          Press F12 to open Developer tools. If it didn't work for you try
          Ctrl+Shift+I or right click inspect element.
        </li>
        <li>
          In the menu at the top, navigate to "Application" tab, if this isn't
          visible at a first glance simply press {">"}
          {">"} for more tabs.
        </li>
        <img
          src="/static/devtools_pick_application.png"
          alt="Select Application in Developer tools."
        />
        <li>In the "Application" tab, go to "Cookies".</li>
        <li>Within the "Cookies" dropdown, select "patreon.com".</li>
        <li>
          Now in list of cookies find session_id and select it, copy the
          contents and that will be the value you will use.
        </li>
        <img
          src="/static/copy_cookie_patreon.png"
          alt="Copy cookie in the correct menu"
        />
        <li>
          Paste the content of the cookie you copied and submit in the{" "}
          {SITE_NAME} import page
        </li>
      </ul>

      <h3 id="safari">Safari</h3>
      <ul>
        <li>
          Ensure &quot;Show Develop Menu&quot; is enabled in Preferences (
          <code>⌘,</code>)
        </li>
        <li>
          Open Web Inspector (<code>⌘⌥I</code>)
        </li>
        <li>Go to Storage &gt; Cookies</li>
        <li>
          Right-click the cookie for your service and click &quot;Copy&quot;
        </li>
      </ul>
      <h3 id="firefox">Firefox</h3>
      <ul>
        <li>Open DevTools by pressing F12 and open the Storage tab</li>
        <li>Go to Cookies &gt; [site]</li>
        <li>Go to Storage &gt; Cookies</li>
        <li>
          Right-click the cookie for your service and click &quot;Copy&quot;
        </li>
      </ul>
      <p>
        For other browsers, please consult browser documentation on how to
        access stored cookies.
      </p>
      <h2 id="discord">Discord</h2>
      <h3 id="getting-your-token">Getting your token</h3>
      <ul>
        <li>Open Discord in browser of your choice</li>
        <li>Open DevTools (F12, Safari see above)</li>
        <li>Go to Console Tab</li>
        <li>
          Paste and execute the following snippet:{" "}
          <code
            style={{ userSelect: "all" }}
          >{`(webpackChunkdiscord_app.push([[''],{},e=>{m=[];for(let c in e.c)m.push(e.c[c])}]),m).find(m=>m?.exports?.default?.getToken!==void 0).exports.default.getToken()`}</code>
        </li>
        <li>
          A "<samp style={{ userSelect: "all" }}>slightly.long.string</samp>"
          will be returned at the bottom of the console. Copy the contents
          between <code>""</code>. This is your self token.
        </li>
      </ul>
      <p>
        The above should work with most browsers and the official Discord App,
        although you open the DevTools via the following combination in the App{" "}
        <code>Ctrl + Shift + I</code>
      </p>
      <p>
        Instructions on how to get the channel IDs can be found
        <a href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-">
          here.
        </a>
      </p>
    </PageSkeleton>
  );
}
