import { IShare, IShareFile } from "#entities/files";
import { apiFetch } from "../fetch";

interface IResult {
  share: IShare;
  share_files: IShareFile[];
  base: unknown;
}

export async function fetchShare(shareID: string) {
  const path = `/share/${shareID}`;
  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
