import { BANNERS_PREPEND, ICONS_PREPEND } from "#env/env-vars";

export function createIconURL(service: string, artistID: string) {
  const path = `${ICONS_PREPEND}/icons/${service}/${artistID}`;

  return path;
}

export function createBannerURL(service: string, artistID: string) {
  const path = `${BANNERS_PREPEND}/banners/${service}/${artistID}`;

  return path;
}
