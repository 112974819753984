import { createBlockComponent, IBlockProps } from "#components/meta";

interface IListUnorderedProps extends IBlockProps<"ul"> {}
interface IListOrderedProps extends IBlockProps<"ol"> {}
interface IListItemProps extends IBlockProps<"li"> {}

export const ListUnordered = createBlockComponent(
  undefined,
  ListUnorderedComponent
);

export const ListOrdered = createBlockComponent(undefined, ListOrderedComponent);

export const ListItem = createBlockComponent(undefined, ListItemComponent);

function ListUnorderedComponent({ ...props }: IListUnorderedProps) {
  return <ul {...props} />;
}

function ListOrderedComponent({ ...props }: IListOrderedProps) {
  return <ol {...props} />;
}

function ListItemComponent({ ...props }: IListItemProps) {
  return <li {...props} />;
}
